<headings class="zoom-item headings py-2">
  <span class="mr-2 title d-flex">
    <span style="font-size: 2rem; cursor: pointer;" (click)="close()" class="material-icons">arrow_back</span>
  </span>

  <button [class.isActive]="uiService.isAssetPanelExpand" (click)="openAsset()" matTooltip="Asset Panel"
    class="header-flat-button ml-0" mat-button type="button">
    <svg width="20" height="20"  fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.6437 10.5107L15.3125 8.51167V2.78395C15.3105 2.66378 15.2741 2.54672 15.2076 2.44672C15.1411 2.34672 15.0474 2.268 14.9375 2.21995L10.25 0.0579514C10.1677 0.0197743 10.0781 0 9.98749 0C9.89684 0 9.80727 0.0197743 9.72499 0.0579514L5.0375 2.21995C4.92901 2.27029 4.8372 2.35077 4.77295 2.45185C4.70869 2.55292 4.6747 2.67035 4.675 2.79021V8.51794L0.362502 10.5107C0.254014 10.5611 0.162201 10.6416 0.0979501 10.7426C0.0336993 10.8437 -0.00029389 10.9611 1.91421e-06 11.081V17.2098C-0.00029389 17.3296 0.0336993 17.4471 0.0979501 17.5482C0.162201 17.6492 0.254014 17.7297 0.362502 17.7801L5.05 19.942C5.13227 19.9802 5.22184 20 5.3125 20C5.40315 20 5.49272 19.9802 5.575 19.942L9.99999 17.8991L14.425 19.942C14.5073 19.9802 14.5968 20 14.6875 20C14.7781 20 14.8677 19.9802 14.95 19.942L19.6375 17.7801C19.746 17.7297 19.8378 17.6492 19.902 17.5482C19.9663 17.4471 20.0003 17.3296 20 17.2098V11.081C20.0008 10.9618 19.9678 10.8449 19.9047 10.7439C19.8416 10.6428 19.7511 10.562 19.6437 10.5107ZM17.8875 11.081L14.6937 12.5411L11.5 11.081L14.6937 9.60833L17.8875 11.081ZM9.99999 1.31128L13.1937 2.78395L9.99999 4.25661L6.8125 2.78395L9.99999 1.31128ZM5.3125 9.60207L8.50624 11.0747L5.3125 12.5411L2.11875 11.081L5.3125 9.60207ZM9.37499 16.8025L5.3125 18.6824L1.25 16.8025V12.0586L5.05 13.7945C5.13057 13.8308 5.21791 13.8496 5.30625 13.8496C5.39459 13.8496 5.48192 13.8308 5.5625 13.7945L9.36249 12.0398L9.37499 16.8025ZM5.9375 8.51167V3.76781L9.73749 5.52247C9.81977 5.56065 9.90934 5.58043 9.99999 5.58043C10.0906 5.58043 10.1802 5.56065 10.2625 5.52247L14.0625 3.76781V8.51794L9.99999 10.3979L5.9375 8.51167ZM18.7562 16.8087L14.6937 18.6887L10.625 16.8087V12.0523L14.4312 13.7945C14.511 13.8299 14.5972 13.8482 14.6844 13.8482C14.7716 13.8482 14.8578 13.8299 14.9375 13.7945L18.7375 12.0398L18.7562 16.8087Z"
        fill="white" />
    </svg>
    <!-- <mat-icon *ngIf="uiService.isAssetPanelExpand"> close</mat-icon> -->
  </button>
  <mat-form-field class="header-project-select mr-1 ml-1" appearance="fill">
    <mat-select [(value)]="uiService.selectedProjectId" (selectionChange)="changeProject($event.value)">
      <mat-option [value]="project.id" *ngFor="let project of uiService.assetProjects">{{ project.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <!-- <img matTooltip="Project scope" (click)="scope()" src="/assets/scope.png" alt="scope" class="mr-1 br-5">-->


  <button (click)="scope()" matTooltip="Project scope" class="header-flat-button mr-1" mat-button type="button"><svg
      width="20" height="20"  fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0V2.07C7.24011 2.29352 5.60439 3.09553 4.34996 4.34996C3.09553 5.60439 2.29352 7.24011 2.07 9H0V11H2.07C2.29352 12.7599 3.09553 14.3956 4.34996 15.65C5.60439 16.9045 7.24011 17.7065 9 17.93V20H11V17.93C12.7599 17.7065 14.3956 16.9045 15.65 15.65C16.9045 14.3956 17.7065 12.7599 17.93 11H20V9H17.93C17.7065 7.24011 16.9045 5.60439 15.65 4.34996C14.3956 3.09553 12.7599 2.29352 11 2.07V0H9ZM9 4.08V6H11V4.09C13.5 4.5 15.5 6.5 15.92 9H14V11H15.91C15.5 13.5 13.5 15.5 11 15.92V14H9V15.91C6.5 15.5 4.5 13.5 4.08 11H6V9H4.09C4.5 6.5 6.5 4.5 9 4.08ZM10 9C9.73478 9 9.48043 9.10536 9.29289 9.29289C9.10536 9.48043 9 9.73478 9 10C9 10.2652 9.10536 10.5196 9.29289 10.7071C9.48043 10.8946 9.73478 11 10 11C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 10 9Z"
        fill="white" />
    </svg>
  </button>


  <!-- <div class="d-flex summary mx-auto">

    <span>
      <mat-icon class="loader" *ngIf="uiService.projectInfo.loading">
        <mat-spinner [diameter]="20"></mat-spinner>
      </mat-icon>
      <button type="button" matTooltip="Feature count"><img src="assets/icons/annotations.svg" class="mr-1">
        <b>{{uiService.projectInfo.annotations}}</b></button>

      <button type="button" matTooltip="Images"><img src="assets/icons/images.svg" class="mr-1">
        <b>{{uiService.projectInfo.images}}</b></button>

      <button type="button" matTooltip="Videos"><img src="assets/icons/video.svg" class="mr-1">
        <b>{{uiService.projectInfo.videos}}</b></button>

      <button type="button" matTooltip="Images with Features"><img src="assets/icons/annotated-images.svg" class="mr-1">
        <b>{{uiService.projectInfo.annotatedImages}}</b></button>

      <button type="button" matTooltip="Images with no Features"><img src="assets/icons/unannotated-images.svg"
          class="mr-1"><b>{{uiService.projectInfo.unAnnotatedImages}}</b></button>

    </span>

  </div> -->
  <div *ngIf="imageId" class="d-flex side-right ml-auto">
    <span>Assign Group(s)</span>
    <ng-select [(ngModel)]="assignGroups" class="assign-group mr-2" [multiple]="true" [items]="groups"
      bindLabel="groupName" bindValue="id" [hideSelected]="true" [clearOnBackspace]="false" [clearable]="false"
      [disabled]="getReadOnlyForCurrentUser()" (add)="selected($event)" (remove)="removed($event)"></ng-select>
  </div>
</headings>
<div class="project-head mb-0 py-1">
  <div class="row" style="margin:0;padding:0">
    <div class="col-3 d-flex">
      <div class="canvas-filename">
        {{imageName}}
      </div>
      <span *ngIf="imageId && isAnnotationVerified" mat-button color="basic">
        <div class="ai-detection-check d-flex text-success">
          <mat-icon class="mr-1">check_circle</mat-icon>
          <span>VERIFIED</span>
        </div>
      </span>
      <button class="ai-detection-button ml-1" *ngIf="!isAnnotationVerified && isAIDetection && imageId"
        (click)="verifyAnnotation()" [disabled]="getReadOnlyForCurrentUser()" mat-button>
        <span> AI detection verified</span>
      </button>

      <mat-slide-toggle class="ml-2 mt-3" [matTooltip]="!isMask ? 'Turn ON masking on image annotation' :
      'Turn OFF masking on image annotation'" [(ngModel)]="isMask" (ngModelChange)="changeMask($event)">
        Mask
      </mat-slide-toggle>


    </div>


    <div class="col-7" style="text-align: center;">
      <button *ngIf="uiService.asset?.assetType === solar" class="mr-1 panel-2d-icon"
        matTooltip="show panels for selected images" (click)="open2DModel()" [disabled]="!imageId">
        <span>
          2D
        </span>
      </button>

      <!-- <mat-slide-toggle *ngIf="uiService.project?.projectType==='thermal'" class="mt-3 mr-4" [matTooltip]="!isTemperature ? 'Turn ON temperature calculation' :
      'Turn OFF temperature calculation'" [(ngModel)]="isTemperature" (ngModelChange)="thermalDrawingEnabledToggle()">
        <mat-icon [class.active]="isTemperature">device_thermostat</mat-icon>
      </mat-slide-toggle> -->
      <button class="ml-1 mr-1 change-palette" *ngIf="uiService.project?.projectType==='thermal'" [matTooltip]="!isTemperature ? 'Turn ON temperature calculation' :
      'Turn OFF temperature calculation'" [class.active]="isTemperature" (click)="thermalDrawingEnabledToggle()">
        <span class="material-icons">
          device_thermostat
        </span>
      </button>
      <!-- <mat-slide-toggle class="mt-3 mr-4" [disabled]="!uiService?.project?.colorPalette"   *ngIf="uiService.project?.projectType==='thermal'" [matTooltip]="!isColorImage ? 'Show color palette image' :
      'Show original image'" [(ngModel)]="isColorImage" (ngModelChange)="colorImageToggle()">
      <mat-icon [class.active]="isColorImage">picture_in_picture_alt</mat-icon>
      </mat-slide-toggle> -->
      <button class="ml-1 mr-2 change-palette" [disabled]="!uiService?.project?.colorPalette"
        *ngIf="uiService.project?.projectType==='thermal'" [matTooltip]="!isColorImage ? 'Show color palette image' :
      'Show original image'" [class.active]="isColorImage" (click)="colorImageToggle()">
        <span class="material-icons">
          picture_in_picture_alt
        </span>
      </button>

      <mat-button-toggle-group class="mat-group mat-group-img-component" [(value)]="taggingMode"
        [disabled]="getReadOnlyForCurrentUser() || !isImageSessionLoading"
        [style.opacity]="getReadOnlyForCurrentUser() ? '.5' : '1'">
        <mat-button-toggle class="drawing-btn" matTooltip="rectangle drawing" mat-icon-button value="rectangle"
          [class.drawing-btn_active]="taggingMode === 'rectangle'" (click)="tagingModeChange()">
          <mat-icon svgIcon="rectangle"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle class="drawing-btn" matTooltip="polygon drawing" mat-icon-button value="polygon"
          [class.drawing-btn_active]="taggingMode === 'polygon'" (click)="tagingModeChange()">
          <mat-icon svgIcon="polygon"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle [hidden]="project.projectType === 'thermal' && thermalDrawingEnabled$.value"
          class="drawing-btn" matTooltip="line drawing" mat-icon-button value="line"
          [class.drawing-btn_active]="taggingMode === 'line'" (click)="tagingModeChange()">
          <mat-icon>timeline</mat-icon>
        </mat-button-toggle>

        <mat-button-toggle *ngIf="project.projectType === 'thermal' && thermalDrawingEnabled$.value" class="drawing-btn"
          matTooltip="point drawing" mat-icon-button value="point" [class.drawing-btn_active]="taggingMode === 'point'"
          (click)="tagingModeChange()">
          <mat-icon>control_point</mat-icon>
        </mat-button-toggle>

      </mat-button-toggle-group>

      <mat-button-toggle-group class="mat-group mat-group-img-component ml-2 mr-2"
        [disabled]="getReadOnlyForCurrentUser() " [hidden]='isTemperature'
        [style.opacity]="getReadOnlyForCurrentUser() ? '.5' : '1'">
        <mat-button-toggle class="drawing-btn" matTooltip="add a context or relation to the image"
          [class.drawing-btn_active]="drawingEnabled$.value" (click)="drawingEnabledToggle()">

          <svg *ngIf="!drawingEnabled$.value" width="25" height="25"  fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="25" rx="3" fill="#c2c2c2" />
            <rect x="16.3638" y="16.3636" width="8.18182" height="8.18182" rx="1" fill="#7e7e7e" />
          </svg>
          <svg *ngIf="drawingEnabled$.value" width="30" height="30"  fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="25" rx="3" fill="#4456F9" />
            <rect x="16.3638" y="16.3636" width="8.18182" height="8.18182" rx="1" fill="#FFFFFF" />
          </svg>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-button-toggle-group class="mat-group">
        <mat-button-toggle matTooltip="Link Label" (click)="addLabels()"
          *ngIf="uiService.asset && imageId && uiService.asset?.assetType != solar">
          <mat-icon>label</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="filter features and report groups" (click)="filter()">
          <mat-icon>filter</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="zoom" (click)="zoomIn()">
          <mat-icon> {{!isZoomIn ? 'fullscreen' : 'fullscreen_exit'}}</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle matTooltip="slider" (click)="sliderView()">
          <mat-icon>
            {{!isVerticalSlider ? 'calendar_view_day' : 'view_array'}}
          </mat-icon>
        </mat-button-toggle>
        
        <mat-button-toggle [disabled]="getScale()" matTooltip="Select a section of the canvas to zoom in on and create a new image with the zoomed-in area." (click)="savedZoomImage()">
          <!-- <mat-icon> save</mat-icon> -->
          <img style="height: 30px;" src="assets/zoomed.jpg" />
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button class="more" mat-icon-button aria-label="More" [matMenuTriggerFor]="manageContextMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #manageContextMenu>
        <button mat-menu-item (click)="manageGroup()" [disabled]="getReadOnlyForCurrentUser()">
          <mat-icon>add_circle_outline</mat-icon>
          <span>MANAGE GROUPS</span>
        </button>
        <button mat-menu-item (click)="manageTitles()">
          <span>SHOW CONTEXTS</span>
        </button>
      </mat-menu>
    </div>

    <div class="col-2 normal-temp">
      <span *ngIf="uiService.project?.minTemperature">
        Normal range: {{ uiService.project.minTemperature}} °C -
        {{ uiService.project.maxTemperature}} °C
      </span>
    </div>
  </div>
</div>

<ng-template let-data #baseModel>
  <app-base-image-model  [projects]="data.projects"
    [isAssetOwner]="data.isAssetOwner" [assetId]="data.assetId" [leftProjectId]="projectId" [dialogRef]="dialogRef"
    [leftImageId]="imageId" [panelsVisible]="data.panelsVisible" [type]="'timeline'" [asset]="data.asset">
  </app-base-image-model>

</ng-template>