export enum inferenceEngineMode {
  Show = 1,
  Hide = 2,
  Beta = 3
}
export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDAMsnLvKGWpqwaL9oRC_NCPN6HdHHKmNs',
    authDomain: 'dive-testing-268508.firebaseapp.com',
    databaseURL: 'https://dive-testing-268508.firebaseio.com',
    projectId: 'dive-testing-268508',
    storageBucket: 'dive-testing-268508.appspot.com',
    messagingSenderId: '206560904252',
    appId: '1:206560904252:web:6c6eeb5347ef1319ecaf39'
  },
  appName: 'DeepDIVE',
  baseAPIUrl: 'https://dive-python-206560904252.europe-west6.run.app',
  baseURL: ' https://diveintegration-api-6rbi6mkm2q-as.a.run.app/',
  engineURL: 'https://diveintegration-api-6rbi6mkm2q-as.a.run.app/ai_trigger_process/',
  imageSimilarity: 'https://diveside-by-side-6rbi6mkm2q-as.a.run.app/dive-image-similarity/',
  engineMode: inferenceEngineMode.Show,
  QRexport: 'https://qr-automation-6rbi6mkm2q-as.a.run.app//generate-qrcode',
  objToTiling: 'https://generate-3d-6rbi6mkm2q-as.a.run.app/tiling_engine/',
  threeDModel: 'https://generate-3d-6rbi6mkm2q-as.a.run.app/3d_model_engine/',
  cancelThreeDModel: 'https://generate-3d-6rbi6mkm2q-as.a.run.app/cancel_process/',
  cropping: 'https://polygon-cropping-6rbi6mkm2q-as.a.run.app/polygon_cropping/',
  alignment: `https://model-alignment-6rbi6mkm2q-as.a.run.app//model_alignment_task/`,
  thermal: `https://thermal-process-6rbi6mkm2q-as.a.run.app/`,
  dxfUrl: 'https://dxf-drawing-6rbi6mkm2q-as.a.run.app/',
  logProcessUrl: 'https://process-logfile-6rbi6mkm2q-as.a.run.app//process_logfiles/',
  imagePolygons:'https://locateimagesinpolygon-6rbi6mkm2q-as.a.run.app//get_images_in_polygon/',
  fetchAnnotations:  'https://dive-python-general-new-6rbi6mkm2q-as.a.run.app/',
  defaultTemplateLocation: `templates/default/Default-template.docx`,
  zoomedImage:'https://canvas-apis-206560904252.europe-west6.run.app/zoom-image',
  modelSize: 600,
  severityLevels: [{
    level: 1,
    color: '#b4e6fd'
  }, {
    level: 2,
    color: '#5cbfed'
  }, {
    level: 3,
    color: '#007eb9'
  }, {
    level: 4,
    color: '#fdeb69'
  }, {
    level: 5,
    color: '#f7dd25'
  }, {
    level: 6,
    color: '#e39a16'
  }, {
    level: 7,
    color: '#c7953a'
  }, {
    level: 8,
    color: '#f17c7c'
  }, {
    level: 9,
    color: '#ff4f4f'
  }, {
    level: 10,
    color: '#d30808'
  }]
};

