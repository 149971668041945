import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, concat, defer, EMPTY, forkJoin, from, iif, merge, Observable, of, Subject, Subscription, throwError, zip } from 'rxjs';
import { catchError, concatMap, debounceTime, finalize, first, map, mergeMap, switchMap, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { AuthProcessService } from 'ngx-auth-firebaseui';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import firebase from 'firebase';
import { fromPromise } from 'rxjs/internal-compatibility';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { Project } from '../projects/item/project.type';
import { IInitialsData } from '../models/initials-data.interface';
import { IFile } from '../models/initials-data.interface';
import { IProspectiveMember } from '../models/prospective-member.interface';
import { UiService } from './ui.service';
import { handleFirebaseError } from 'src/app/shared/error-handler';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  public userProjects;
  public CONSTANTS;
  public industries;
  public selectedGroups = []; // TODO: something strange, it's for ImageGroupDialogComponent, not for backend service
  public DOMAIN_CONTROL;
  public USED_DATA;
  public getFoldersLoading$ = new BehaviorSubject<any>(true);
  public currentUser: firebase.User;
  public currentUserData;
  public cancelFetchImage$ = new Subject<any>();
  public cancelFetchProjectImage$ = new Subject<any>();
  public cancelFetchProjectAnnotations$ = new Subject<any>();


  constructor(
    private firestore: AngularFirestore,
    private authProcess: AuthProcessService,
    private auth: AngularFireAuth,
    private storage: AngularFireStorage,
    private httpClient: HttpClient,
    private uiService: UiService
  ) {
    this.currentUser = this.authProcess.user;
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.currentUser = user;
        this.firestore
          .collection('configuration')
          .doc('constants')
          .get()
          .subscribe((constants) => {
            this.CONSTANTS = constants.data();
          });
        this.firestore
          .collection('configuration')
          .doc('company')
          .get()
          .subscribe((company: any) => {
            this.industries = company.data().industries;
          });
        this.getDomainControl(this.currentUser);
        //this.restoreImages();
        // this.addProjectIdToAnnotations();
        /* this.firestore
         .collection(`annotations`, (ref) =>
           ref.where('projectId', '==', "fA7btOoQc6AENXKsbidP")).get().
         subscribe(annotations => {
           annotations.docs.forEach(doc => {
             this.firestore
             .collection(`annotations`).doc(doc.id).delete();
           });
         });
         */
      }
    })
  }

  /**For testing purpose */
  restoreImages() {
    this.firestore
      .collection('images', (ref) =>
        ref
          .where('deleted', '==', false)
      ).get().subscribe((response: any) => {
        response.docs.forEach(element => {
          if (element.data().fileUrl && !element.data().originalFileSize) {
            this.httpClient.get(
              `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/restoreFileSizes?id=${element.id}`,
              {
              }
            ).subscribe();

          }
          if (!element.data().fileUrl) {
            this.firestore
              .collection('images').doc(element.id).update({ deleted: true })
          }
        });
      });
  }

  domainControlValid(type) {
    if (!this.DOMAIN_CONTROL) {
      return false;
    } else if (this.DOMAIN_CONTROL.unlimited) {
      return true;
    } else {
      switch (type) {
        case 'invite':
          if (this.DOMAIN_CONTROL.invitedUsers > this.USED_DATA.invitedUsers) {
            return true;
          } else {
            return false;
          }
        case 'projects':
          if (this.DOMAIN_CONTROL.projects > this.USED_DATA.projects) {
            return true;
          } else {
            return false;
          }
        case 'assets':
          if (this.DOMAIN_CONTROL.assets > this.USED_DATA.assets) {
            return true;
          } else {
            return false;
          }

        case 'storage':
          if (this.DOMAIN_CONTROL.storage > this.USED_DATA.usedStorage) {
            return true;
          } else {
            return false;
          }
      }
    }
  }


  getProjects() {
    return this.userProjects.valueChanges({ idField: 'id' })
      .pipe(
        debounceTime(500),
        handleFirebaseError(this.auth, 'getProjects'),
        map((projects: any[]) => projects.filter(project => !project.deletedDate))
      )
  }

  getProjectById(id): Observable<any> {
    return this.firestore.collection('projects').doc(id).valueChanges({ idField: 'id' })
  }

  saveLogos(file: IFile) {
    const ref = this.storage.ref('logos').child(uuidv4());
    if (file.file) {
      return from(ref.put(file.file)).pipe(switchMap((response) => {
        return from(response['ref'].getDownloadURL()).pipe(switchMap(downloadUrl => {
          return of({
            link: downloadUrl,
            bucketName: response['ref'].name,
            name: file.file.name
          });
        }));
      }));
    } else {
      return of(null);
    }
  }

  refreshProjects() {
    const { user } = this.authProcess;
    console.log(this.authProcess);

    this.userProjects = this.firestore.collection('projects', (ref) =>
      ref.where(`people.${user.uid}.role`, 'in', [
        'editor',
        'owner',
        'reviewer',
        'approver',
        'viewer'
      ])
    );

  }

  getProject(id: string): Observable<any> {
    return this.firestore.collection(`projects`).doc(id).valueChanges().pipe(
      handleFirebaseError(this.auth, 'getProject')
    );
  }

  markProjectDeleted(id: string) {
    return this.userProjects.doc(id).update({
      deletedDate: new Date().toISOString(),
      deletedBy: this.getCurrentUser().uid
    });
  }

  addProjectPeople(id: string, role: string, readonly: boolean, userId: string, email: string) {
    return this.httpClient.post(
      `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/addMembersToProjectandAsset`,
      {
        projectId: id,
        role: role,
        readonly: readonly,
        email: email,
        userId: userId
      }
    );

  }

  getImageTemerature(id: string) {
    return this.httpClient.post(
      `${environment.thermal}/calculate_temp/`,
      {
        imageId: id
      }
    );
  }

  convertColorPalette(id: string, colorPalette: string) {
    return this.httpClient.post(
      `${environment.thermal}/cloud_task_function/`,
      {
        "projectId": id,
        "colorPalette": colorPalette
      }
    );
  }

  anomalyDetection(id: string, tempMin: string, tempMax: string, deviation = null) {
    return this.httpClient.post(
      `${environment.thermal}/detect_anomaly_cloud_task/`,
      {
        "projectId": id,
        "tempMax": tempMax,
        "tempMin": tempMin,
        "standard_deviation": deviation
      }
    );
  }


  getTemperature(imageId: string, points: number[], closedType: string = "") {
    return this.httpClient.post(
      `${environment.thermal}calculate_temperature_from_image/`,
      {
        "imageId": imageId,
        "points": points,
        "closedType": closedType
      }
    );
  }
  addImageToCache(imageId: string) {
    return this.httpClient.post(
      `${environment.thermal}calculate_temperature_from_image/`,
      {
        "imageId": imageId
      }
    );
  }

  updateProjectOwner(projectId: string, newOwnerId: string) {
    const { user } = this.authProcess;
    const uid = user.uid;
    const projectRef = this.firestore.collection('projects').doc<Project>(projectId);
    return projectRef.valueChanges().pipe(
      handleFirebaseError(this.auth, 'updateProjectOwner'),
      take(1),
      switchMap(project => {
        if (project.people[user.uid]) {
          return projectRef.update({
            people: {
              ...project.people,
              [uid]: {
                role: 'editor'
              },
              [newOwnerId]: {
                role: 'owner'
              }
            }
          })
        }
        return of();
      })
    )
  }

  updateProjectTimestamp(id: string): Promise<any> {
    return this.firestore.collection('projects')
      .doc<Project>(id).update({ changedAt: new Date().toISOString() });
  }

  updateProjectName(id: string, name: string): Promise<any> {
    return this.firestore.collection('projects')
      .doc<Project>(id).update({ name: name });
  }

  getUser(id: string): Observable<any> {
    return this.firestore.collection(`users`).doc(id).get();
  }
  getUser$(id: string): Observable<any> {
    return this.firestore.collection(`users`).doc(id).valueChanges({ idField: '_id' });
  }

  addProjectInitials$(initialsDate: IInitialsData, projectId: string) {
    const { user } = this.authProcess;
    const initialsRef = this.firestore.collection('projects').doc(projectId).collection('initials');
    return initialsRef.add(
      { ...initialsDate, createdBy: user.uid, createdTime: firebase.firestore.FieldValue.serverTimestamp() }
    );

  }

  addProjectMeasurements$(projectId: string, key: string, points: any[]): Promise<any> {
    const ref = this.firestore.collection('projects').doc(projectId).collection('measurements');
    return ref.add({
      [key]: points
    }
    );
  }

  getProjectMeasurements$(projectId: string): Observable<any> {
    const docRef = this.firestore.collection('projects').doc(projectId).collection('measurements').get().pipe(
      first(),
      map(data => data)
    );
    return docRef;
  }

  updateProjectMeasurements$(projectId: string, measurementId: string, key: string, points: any[]): Promise<any> {
    const ref = this.firestore.collection('projects').doc(projectId).collection('measurements').doc(measurementId);;
    return ref.update({
      [key]: points
    }
    );
  }

  updateProjectInitials$(initialsData: any, projectId: string): Promise<any> {
    const { user } = this.authProcess;
    const initialsRef = this.firestore.collection('projects').doc(projectId).collection('initials').doc(initialsData.id);
    return initialsRef.update({
      ...initialsData, updatedBy: user.uid
    })

  }

  revisedProjectInitials$(initialsDate: any, projectId: string): Promise<any> {
    const { user } = this.authProcess;
    const initialsRef = this.firestore.collection('projects').doc(projectId).collection('initials').doc(initialsDate.id);
    return initialsRef.update({
      revision: initialsDate.revision + " revised"
    })

  }

  removeProjectInitials$(projectId: string, id: string): Promise<any> {
    return this.firestore.collection('projects').doc(projectId).collection('initials').doc(id).delete();
  }

  getUsers(ids: string[]): Observable<any> {
    let batches: any = [];
    while (ids.length) {
      const batch = ids.splice(0, 10);
      batches = batches.concat(...[this.firestore
        .collection(`users`, (ref) =>
          ref.where(firebase.firestore.FieldPath.documentId(), 'in', [...batch])
        )
        .get().pipe(
          handleFirebaseError(this.auth, 'getUsers'),
          map((actions) => {
            return actions.docs.map(a => {
              const data: any = a.data();
              const _id = a.id;
              return { _id, ...data };
            });

          })
        )]);
    }


    return combineLatest(batches).pipe(map((out: any) => {
      return out.flat()
    }));
    /* return this.firestore
       .collection(`users`, (ref) =>
         ref.where(firebase.firestore.FieldPath.documentId(), 'in', ids)
       )
       .valueChanges({ idField: '_id' }).pipe(
         handleFirebaseError(this.auth)
       );
       */
  }

  getProspective(projectId: string): Observable<any> {
    return this.firestore
      .collection(`prospective_members`, (ref) =>
        ref.where('projectId', '==', projectId)
      )
      .valueChanges({ idField: '_id' }).pipe(
        handleFirebaseError(this.auth, 'getProspective')
      );
  }

  getProspectiveByEmail(): Observable<any> {
    const { user } = this.authProcess;
    return this.firestore
      .collection(`prospective_members`, (ref) =>
        ref.where('email', '==', user.email)
      )
      .valueChanges({ idField: '_id' }).pipe(
        handleFirebaseError(this.auth, 'getProspectiveByEmail')
      );
  }

  getProspectiveById(id: string): Observable<any> {
    return this.firestore
      .collection(`prospective_members`).doc(id).get()
  }

  getTags<T extends any = any>(
    projectId: string
  ): Observable<{ tags: any[] }> {
    if (!projectId) {
      return of()
    }
    return this.firestore
      .collection(`tags`, (ref) => ref.where('projectId', '==', projectId))
      .valueChanges()
      .pipe(
        handleFirebaseError(this.auth, 'getTags'),
        map((tagCollections) => {
          if (!tagCollections.length) return { tags: [] };

          const collection = tagCollections[0] as any;

          return { tags: collection.tags.filter(x => x.status !== 'deleted') };
        })
      );
  }

  getReports(projectId): Observable<any> {
    // const { user } = this.authProcess;
    return this.firestore
      .collection(`reports`, (ref) =>
        ref.where('reportData.projectId', '==', projectId)
      ).valueChanges({ idField: '_id' }).pipe(
        handleFirebaseError(this.auth, 'getReports')
      );
  }

  getProjectPeople(peopleIds): Promise<any> {
    const users = [];
    let i = 0;
    return new Promise((resolve) => {
      peopleIds.forEach(people => {
        this.getUser(people).pipe(take(1)).subscribe(user => {
          i++;
          users.push(user.data())
          if (i == peopleIds.length)
            resolve(users);
        });

      });

    });
  }

  getTemplates(projectId: string): Observable<any> {
    if (!projectId) {
      return of();
    }
    const { user } = this.authProcess;
    return this.firestore
      .collection(`reports`, (ref) =>
        ref.where('projectId', '==', projectId).where('isTemplate', '==', true)
      )
      .valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'getTemplates')
        /* switchMap(r => {
           return forkJoin([
             of(r),
             this.storage.ref('templates').child('default').listAll()
           ]);
         }
         ),
         switchMap(([templates, existingTemplates]: any) => {
           const defaultTemplate = existingTemplates.items.find(x => x.fullPath.includes('Default-template'));
           if (defaultTemplate) {
             return forkJoin([
               fromPromise(defaultTemplate.getDownloadURL()),
               of(templates)]);
           }
           return of([null, templates]);
         }),
         map(([defaultTemplateUri, templates]) => {
           if (!defaultTemplateUri) return templates;
           return [
             {
               docxURl: defaultTemplateUri,
               id: 'default-template',
               isTemplate: true,
               isDefault: true,
               title: 'Default-template'
             },
             ...templates
           ]
         }
         )
         */
      );
  }

  getTemplateById(id): Observable<any> {
    const { user } = this.authProcess;
    return this.firestore
      .collection(`reports`).doc(id).get();

  }

  inviteUserToProject(
    projectId: string,
    email: string,
    userRole: string,
    readonly: boolean,
    assetName?: string
  ): Observable<any> {
    return combineLatest(
      this.firestore.collection(`users`, (ref) => ref.where('email', '==', email)).get(),
      // this.firestore.collection('prospective_members', ref => ref.where('email', '==', email)).get(),
      this.firestore.collection('projects').doc(projectId).get()
    ).pipe(
      mergeMap(([users, project]) => {
        const { user } = this.authProcess;
        const projectData: any = project.data();
        if (users.docs.length) {
          // invited members exist, add directly to the project 
          this.addProjectPeople(projectId, userRole, readonly, users.docs[0].id, email).subscribe();
          return fromPromise(
            this.firestore.collection('mail').add({
              to: [email],
              message: {
                subject: `${environment.appName}: invite to collaborate on project ${assetName} : ${projectData.name}`,
                html: `
                Dear ${email},<br><br>
                <b>${this.currentUser.displayName}</b> has invited you to collaborate with them on DeepDIVE for the analysis and  processing of the inspection of project <b>${assetName} : ${projectData.name}</b>.
                <br>
                You may log into DeepDIVE and access the Project, it has been automatically added to your dashboard. 
                <br>
                If you do have any feedback on the platform and its features do write to us at info@oceansai.tech.`  + this.emailFooter(),
              },
            })
          );

        } else {
          return fromPromise(
            this.firestore.collection('prospective_members').add({
              email,
              invited_by: user.uid,
              projectId,
              role: userRole,
              timestamp: new Date(),
              readonly
            })
          ).pipe(
            mergeMap((result) => {
              const params = `addToProject=${projectId}&role=${userRole}&readonly=${readonly}&id=${result.id}`;
              return fromPromise(
                this.firestore.collection('mail').add({
                  to: [email],
                  message: {
                    subject: `${environment.appName}: invite to collaborate on project ${assetName}: ${projectData.name}`,
                    html: `
                    Dear ${email},<br><br>
                    <b>${this.currentUser.displayName}</b>
                    has invited you to collaborate with them on DeepDIVE for the analysis and  processing of the inspection of project <b>${assetName} : ${projectData.name}</b>.
                    <br>
                    You have been sent this email since you don’t have an account on DeepDIV yet.
                    <br>
                    To accept invitation to the project 
                    Please click on this <a href='https://${environment.firebase.authDomain}/dashboard/projects?${params}'>link<a> and proceed to create an account on DeepDIVE.
                    <br>
                    Once your account is created you may access the project from your Dashbaord. 
                    <br>
                    Welcome onboard DeepDIVE and Happy collaborations!
                    <br>
We hope you find the features and functionality on the platfrom useful and beneficial to the work you will do. If you do have any feedback or difficulty accessing on the platform and its features do write to us at info@oceansai.tech`  + this.emailFooter(),
                  }
                })
              );
            })
          );
        }
      })
    );
  }

  sendEmailWithNotification(email: string, projectName: string, assetName: string) {
    const user = this.getCurrentUser();
    return this.firestore.collection('mail').add({
      to: [email],
      message: {
        subject: `${environment.appName} change of ownership ${assetName} : ${projectName}`,
        html: `<b> ${user.displayName} </b> has transferred the ownership  of <b>${assetName} : ${projectName}</b>.
        <br>
        You have been made the new owner of the project <b>${projectName}</b>.
       <br>
        The project ownership is automatically transferred. If you wish to transfer the ownership to another user you may login to DeepDIVE 
        <a href="https://${environment.firebase.authDomain}">link<a>
         and transfer the ownership of the project in the members section. 
         <br>
         If you do have any feedback on the platform and its features do write to us at info@oceansai.tech.` + this.emailFooter()
      },
    });
  }

  accountRequest(html: string, params: any): Observable<any> {
    return fromPromise(
      this.firestore.collection('mail').add({
        to: ['sales@oceansai.tech'],
        message: {
          subject: 'Account request',
          html: html
        },
      })
    ).pipe(
      mergeMap((result) => {
        return fromPromise(
          this.firestore.collection('account-request').add(params)
        );
      })
    );

  }

  updateProspectiveMember(id: string, updateObj: Partial<IProspectiveMember>) {
    return fromPromise(
      this.firestore.collection('prospective_members').doc(id).update(updateObj)
    );
  }

  addUserToProject(projectId: string, userRole: string, readonly: boolean): Observable<any> {
    const { user } = this.authProcess;
    const temp = `people.${user.uid}`;
    const update = {};
    update[temp] = {
      role: userRole,
      readonly
    };

    return fromPromise(
      this.firestore.collection('projects').doc(projectId).update(update)
    );
  }

  changeReadOnlyForProjectMember$(projectId: string, userRole: string, readonly: boolean, memberId: string) {
    const { user } = this.authProcess;
    const temp = `people.${memberId}`;
    const update = {};
    update[temp] = {
      role: userRole,
      readonly
    };

    return fromPromise(
      this.firestore.collection('projects').doc(projectId).update(update)
    );
  }

  changeReadOnlyForProspectiveMember$(memberId: string, readonly: boolean) {
    return fromPromise(
      this.firestore.collection('prospective_members').doc(memberId).update({
        readonly
      })
    );
  }

  changeRoleOnlyForProspectiveMember$(memberId: string, role: string) {
    return fromPromise(
      this.firestore.collection('prospective_members').doc(memberId).update({
        role
      })
    );
  }

  removeUserFromProject(projectId: string, userId: string): Observable<any> {
    const temp = `people.${userId}`;
    const update = {};
    update[temp] = firebase.firestore.FieldValue.delete();
    return fromPromise(
      this.firestore.collection('projects').doc(projectId).update(update)
    );
  }

  removeProspectiveMember(memberId: string): Observable<any> {
    return fromPromise(
      this.firestore.collection('prospective_members').doc(memberId).delete()
    );
  }

  createProject(
    name: string,
    inspectionDate: string,
    assetId: string,
    members: any[],
    projectType: string,
    cameraType: string,
    cameraModel: string,
    inspectionType: string,
    classDefinition: string
  ): Promise<any> {
    const { user } = this.authProcess;
    if (!user) {
      return Promise.reject('Not logged in');
    }

    let projectMembers = {};
    members.forEach(member => {
      projectMembers[member.id] = {
        role: member.role,
        readonly: member.readonly
      };
    });

    let cameraParams = {};
    if (projectType === 'thermal') {
      cameraParams = {
        cameraType: cameraType,
        cameraModel: cameraModel,
      };
    }

    // Fetch classDefinition and create project in sequence
    if (classDefinition) {
      return this.firestore.collection('configuration').doc('standard').collection(classDefinition)
        .get().pipe(
          map(actions => {
            return actions.docs.map(a => {
              const data: any = a.data();
              const id = a.id;
              return { id, ...data };
            });
          }),
          switchMap(classDefinitions => {
            // Now create the project with fetched data
            return this.createProjectWithFirestore(
              name,
              inspectionDate,
              assetId,
              user.uid,
              projectType,
              inspectionType,
              projectMembers,
              cameraParams
            ).then(projectId => {
              // Update another collection based on the newly generated projectId
              return this.addStandardTag(projectId, classDefinitions, classDefinition);
            });
          })
        ).toPromise();
    } else {
      // If no classDefinition, proceed directly to create the project
      return this.createProjectWithFirestore(
        name,
        inspectionDate,
        assetId,
        user.uid,
        projectType,
        inspectionType,
        projectMembers,
        cameraParams
      ).then(projectId => {
        // Include default template 
        return this.addDefaultTemplate(projectId);
      });
      ;
    }
  }

  private createProjectWithFirestore(
    name: string,
    inspectionDate: string,
    assetId: string,
    ownerId: string,
    projectType: string,
    inspectionType: string,
    members: any,
    cameraParams: any
  ): Promise<string> { // Return type changed to Promise<string>
    return new Promise<string>((resolve, reject) => {
      this.firestore.collection('projects').add({
        ...cameraParams,
        ...{
          name,
          createdDate: new Date(),
          assetId,
          inspectionDate,
          projectType,
          inspectionType,
          people: {
            [ownerId]: {
              role: 'owner',
              readonly: false
            },
            ...members
          }
        }
      }).then(docRef => {
        resolve(docRef.id); // Resolve with the newly generated project ID
      }).catch(error => {
        reject(error);
      });
    });
  }

  private async addStandardTag(projectId: string, standardTags: any[], classDefinition): Promise<void> {
    const { user } = this.authProcess;
    // Prepare tags data
    let tagsData = standardTags.map(tag => ({
      id: uuidv4(),
      tag: tag.title,
      sensitivity: true,
      uuid: user.uid,
      status: 'active',
      created_at: new Date(),
      aiTag: false,
      description: tag.description,
      chart: tag.chart || null,
      levels: tag.levels,
      color: this.CONSTANTS.tags_colors[Math.floor(Math.random() * this.CONSTANTS.tags_colors.length)],
    }));

    // Update 'tags' collection
    await this.addReportStandardTempelate(projectId, classDefinition);
    // Update Firestore collection 'tags' with the prepared data
    return this.firestore.collection('tags').add({
      projectId: projectId,
      tags: tagsData
    }).then(() => {
    }).catch(error => {
      console.error('Error adding tags:', error);
      throw error; // Optional: rethrow the error for handling in calling code
    });
  }

  private async addReportStandardTempelate(projectId, classDefinition): Promise<void> {
    const { user } = this.authProcess;
    const reportRef = this.storage.ref('templates/standard').child(`${classDefinition}.docx`);
    // Get download URL of the report template file as observable
    const url$: Observable<string> = reportRef.getDownloadURL().pipe(
      finalize(() => {
        console.log('Download URL retrieved successfully.');
      })
    );
    // Extract URL from observable
    const url: string = await url$.toPromise();
    const collectionRef = this.firestore.collection('reports');
    await collectionRef.add({
      projectId: projectId,
      title: classDefinition.toUpperCase() + ' Class Standard',
      isTemplate: true,
      isStandard: true,
      isDefault: true,
      createdAt: new Date(),
      createdBy: user.uid,
      docxURl: url,
    });

  }

  private async addDefaultTemplate(projectId: string) {
    const { user } = this.authProcess;
    const reportRef = this.storage.ref(environment.defaultTemplateLocation);
    // Get download URL of the report template file as observable
    const url$: Observable<string> = reportRef.getDownloadURL().pipe(
      finalize(() => {
        console.log('Download URL retrieved successfully.');
      })
    );
    // Extract URL from observable
    const url: string = await url$.toPromise();
    const collectionRef = this.firestore.collection('reports');
    return collectionRef.add({
      projectId: projectId,
      title: 'Default Template',
      isTemplate: true,
      isStandard: false,
      isDefault: true,
      createdAt: new Date(),
      createdBy: user.uid,
      docxURl: url,
    });
  }


  createFolder(path, name) {
    const { user } = this.authProcess;

    if (!user) {
      return Promise.reject('Not logged in');
    }

    return this.firestore.doc(path).collection('folders').add({
      name,
      createdAt: new Date(),
      createdBy: user.uid,
    });
  }

  getFolderContents$(folderPath, projectId): Observable<any> {
    return this.firestore
      .collection('images', (ref) =>
        ref
          .where('folderPath', '==', folderPath)
          .where('projectId', '==', projectId)
          .where('deleted', '==', false)
          .orderBy('fileName')
      )
      .valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'getFolderContents$'),
        map(images => images)
      );
  }

  // Function to retrieve subcollection names as an Observable
  getStandardClasses(): Observable<any> {

    return this.firestore.collection('configuration').doc('standard')
      .get().pipe(
        handleFirebaseError(this.auth, 'getFolderContents$'),
        map(actions => {
          return actions.data().types;
        }),
      );

  }



  // =======================================
  // Get images with infinite scroll pagination
  latestEntry: any; // - for next scroll
  latestEntryForPrev: any;  // - for prev scroll
  // You need to return the doc to get the current cursor.
  getCollection(ref, queryFn?): Observable<any[]> {
    return this.firestore.collection(ref, queryFn).snapshotChanges().pipe(
      handleFirebaseError(this.auth, 'getCollection'),
      map(actions => {
        return actions.map(a => {
          const data: any = a.payload.doc.data();
          const id = a.payload.doc.id;
          const doc = a.payload.doc
          return { id, ...data, doc };
        });
      }),
      // tap(data => {
      //   // console.log('data 00', data);
      //   // this.firstEntry = data[0] ? data[0].doc : null;
      //   // this.latestEntry = data[data.length - 1] ? data[data.length - 1].doc : null;
      // })
    );
  }
  // In your first query you subscribe to the collection and save the latest entry
  getFolderContentsFirst$(folderPath, projectId) {
    return this.getCollection('images', ref => ref
      .where('folderPath', '==', folderPath)
      .where('projectId', '==', projectId)
      .where('deleted', '==', false)
      .orderBy('fileName')
      .limit(32)).pipe(
        tap(data => {
          this.latestEntry = data[data.length - 1] ? data[data.length - 1].doc : null;
        })
      )
  }

  getFolderContentsAll$(folderPath, projectId) {
    return this.getCollection('images', ref => ref
      .where('folderPath', '==', folderPath)
      .where('projectId', '==', projectId)
      .where('deleted', '==', false)
      .orderBy('fileName')).pipe()
  }

  getVideoContentsAll$(folderPath, projectId) {
    return this.getCollection('videos', ref => ref
      .where('folderPath', '==', folderPath)
      .where('projectId', '==', projectId)
      .where('deleted', '==', false)
      .orderBy('fileName')).pipe()
  }

  getAllProjectImages$(projectId) {
    return this.getCollection('images', ref => ref
      .where('projectId', '==', projectId)
      .where('deleted', '==', false)
      .orderBy('fileName')).pipe()
  }

  // first query for tagging page
  firstForTagging(folderPath, projectId, latestEntry = this.latestEntry) {
    return this.getCollection('images', ref => ref
      .where('folderPath', '==', folderPath)
      .where('projectId', '==', projectId)
      .where('deleted', '==', false)
      .orderBy('fileName')
      .startAt(latestEntry)
      .limit(32)).pipe(
        tap(data => {
          this.latestEntry = data[data.length - 1] ? data[data.length - 1].doc : null;
          this.latestEntryForPrev = data[0] ? data[0].doc : null;
        })
      )
  }

  getFolderContentsNext$(folderPath, projectId) {
    if (!this.latestEntry) { return of([]); }
    return this.getCollection('images', ref => ref
      .where('folderPath', '==', folderPath)
      .where('projectId', '==', projectId)
      .where('deleted', '==', false)
      .orderBy('fileName')
      .startAfter(this.latestEntry)
      .limit(32)).pipe(
        tap(data => {
          this.latestEntry = data[data.length - 1] ? data[data.length - 1].doc : null;
        })
      )
  }
  // realized only for tagging page at the moment
  getFolderContentsPrev$(folderPath, projectId) {
    if (!this.latestEntryForPrev) { return of([]); }
    return this.getCollection('images', ref => ref
      .where('folderPath', '==', folderPath)
      .where('projectId', '==', projectId)
      .where('deleted', '==', false)
      .orderBy('fileName', 'desc')
      .startAfter(this.latestEntryForPrev)
      .limit(32)
    ).pipe(
      map(images => images.reverse()),
      tap(data => {
        if (data[0]) {
          this.latestEntryForPrev = data[0].doc;
        }
        // this.latestEntryForPrev = data[0] ? data[0].doc : null;
      }),
    )
  }

  changeFolderForImage(imageId: string, projectId: string, folderPath: string) {
    return this.firestore.collection('images').doc(imageId).update({
      projectId,
      folderPath
    });
  }


  getFolders$(projectId): Observable<any> {
    return this.firestore
      .collection('projects')
      .doc(projectId)
      .collection('folders')
      .get().pipe(switchMap(folders => {
        let copyFolders = {
          ...folders,
          docs: folders.docs.filter(item => !item.data().deletedDate)
        }
        return of(copyFolders);
      }));
  }

  renameFolder(path: string, name: string) {
    return this.firestore.doc(path).update({ name });
  }

  scopeUpdate(projectId: string, projectScope: string) {
    return this.firestore.collection('projects').doc(projectId).update({ projectScope });
  }

  getProjectImages(projectId) {
    return this.firestore
      .collection('images', (ref) => ref.where('projectId', '==', projectId).where('deleted', '==', false))
      .get();
  }

  getProjectAnnotations(projectId) {
    return this.firestore
      .collection(`annotations`, (ref) =>
        ref.where('projectId', '==', projectId)
      )
      .get();
  }

  getProjectImages$(projectId: string,): Observable<any> {
    return this.firestore
      .collection('images', (ref) => ref.where('projectId', '==', projectId).where('deleted', '==', false))
      .valueChanges({ idField: 'id' }).pipe()
  }

  getProjectVideos$(projectId: string,): Observable<any> {
    return this.firestore
      .collection('videos', (ref) => ref.where('projectId', '==', projectId).where('deleted', '==', false))
      .valueChanges({ idField: 'id' }).pipe()
  }

  moveProjectGroups(projectId): Observable<any> {
    return forkJoin([
      this.firestore.collection('image_groups', (ref) => ref.where('projectId', '==', projectId)).get(),
      this.firestore.collection('images', (ref) => ref.where('projectId', '==', projectId).where('deleted', '==', false)).get()]).pipe(mergeMap(([groups, dbImages]) => {
        const docs: any = groups.docs;
        const images: any = dbImages.docs;

        const db = this.firestore.firestore;
        const batch = db.batch();

        docs.forEach(doc => {
          const docRef = db.collection('projects').doc(projectId).collection('image_groups').doc();

          const groupImages = images.filter(x => {
            if (x.data().groupIds) {
              return x.data().groupIds.includes(doc.id);
            }
          });
          groupImages.forEach(img => {
            let groupIds = img.data().groupIds;
            groupIds = [...groupIds.filter(x => x !== doc.id), docRef.id];
            batch.update(img.ref, {
              groupIds
            });
          });
          const newDoc = {
            groupName: doc.data().name,
            images: groupImages.map(r => r.id)
          };
          batch.set(docRef, newDoc);
          batch.delete(doc.ref);
        });
        return fromPromise(batch.commit());
      }));
  }

  getProjectImageGroups(projectId): Observable<any> {
    // this.moveProjectGroups(projectId).subscribe(response => { });
    return this.firestore
      .collection('projects').doc(projectId).collection('image_groups')
      .valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'getProjectImageGroups')
      );
  }

  deleteFolderImage(id): Observable<any> {
    return fromPromise(this.firestore.collection('images').doc(id).delete());
  }

  markFolderImageDeleted(id): Observable<any> {
    return fromPromise(this.firestore.collection('images').doc(id).update({
      deletedDate: new Date().toISOString(),
      deletedBy: this.getCurrentUser().uid,
      deleted: true
    }));
  }

  getImage$<T extends any>(imageId: string): Observable<T> {
    return this.firestore.collection('images').doc<T>(imageId).get().pipe(
      takeUntil(this.cancelFetchImage$),
      map((image: any) => {
        return {
          ...image.data(),
          id: imageId,
          type: 'images'
        };
      }),
      handleFirebaseError(this.auth, 'getImage$')
    );
  }

  getImage<T extends any>(imageId: string): Observable<T> {
    return this.firestore.collection('images').doc<T>(imageId).get().pipe(
      map((image: any) => {
        return {
          ...image.data(),
          id: imageId,
          type: 'images'
        }
      }),
      handleFirebaseError(this.auth, 'getImage$')
    );
  }

  getProjectFirstImage<T extends any>(projectId: string): Observable<T> {
    return this.firestore
      .collection(`images`, (ref) =>
        ref.where('projectId', '==', projectId)
          .where('deleted', "==", false)
          .limit(1)
      ).get().pipe(
        map((image: any) => {
          return image.docs.length ? image.docs[0].data() : null
        }),
        handleFirebaseError(this.auth, 'getProjectFirstImage')
      );
  }


  getPanels<T extends any>(projectId: string, handleId: string): Observable<T> {
    return this.firestore
      .collection(`panel-annotations`, (ref) =>
        ref.where('projectId', '==', projectId)
          .where('handleId', "==", handleId)
          .limit(1)
      ).get().pipe(
        map((panel: any) => {
          return panel.docs.length ? { ...panel.docs[0].data() } : null
            ;
        }),
        // handleFirebaseError(this.auth, 'getPanels')
      );
  }


  getImageWithAnnotations(imageId: string): Observable<any> {
    return combineLatest(
      this.firestore
        .collection(`images`).doc(imageId).get(),
      this.firestore
        .collection(`annotations`, (ref) =>
          ref.where('imageId', '==', imageId)).get()
    ).pipe(
      mergeMap(([image, annotations]: any) => {
        return Promise.resolve({
          ...image.data(),
          id: imageId,
          type: 'images',
          tags: annotations.docs.length ?
            annotations.docs[0].data().polygons || []
            : []
        })
      })
    )
  }

  getExclude3DImage$(projectId): Observable<any> {
    return this.firestore
      .collection('projects').doc(projectId)
      .collection('3d_images').doc("excluded_images")
      .valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'getExclude3DImage$'),
        map(r => {
          return r ? r.images : []
        })
      );
  }

  exclude3DImage$(imageId, projectId, exclude, totalExcludedImages): Observable<any> {
    if (totalExcludedImages == 0) {
      return fromPromise(
        this.firestore.collection('projects').doc(projectId)
          .collection('3d_images').doc("excluded_images").set({
            images: [imageId]
          })
      );
    } else {
      return fromPromise(
        this.firestore.collection('projects').doc(projectId)
          .collection('3d_images').doc("excluded_images").update({
            images: exclude ? firebase.firestore.FieldValue.arrayUnion(imageId)
              : firebase.firestore.FieldValue.arrayRemove(imageId)
          })
      );

    }
  }

  getVideo$<T extends any>(videoId: string): Observable<T> {
    return this.firestore.collection('videos').doc<T>(videoId).get().pipe(
      map((video: any) => {
        return {
          ...video.data(),
          id: videoId,
          type: 'videos'
        }
      }),
      handleFirebaseError(this.auth, 'getVideo$')
    );
  }


  updateImageGroup$(group: { id: string; groupName: string; images?: string[] }, imageIds: string[], projectId: string): Observable<any> {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    return fromPromise(
      this.firestore.collection('projects').doc(projectId)
        .collection('image_groups').doc(group.id).update({
          ...group,
          images: group.images ? [...group.images, ...imageIds].filter(onlyUnique) : imageIds
        })
    );
  }

  removeImagesGroup$(group: { id: string; groupName: string; images?: string[] }, imageIds: string[], projectId: string): Observable<any> {
    return fromPromise(
      this.firestore.collection('projects').doc(projectId)
        .collection('image_groups').doc(group.id).update({
          ...group,
          images: group.images.filter(imageId => !imageIds.includes(imageId))
        })
    );
  }

  getImageGroup(projectId, imageId): Observable<any> {

    return this.firestore.collection('projects').doc(projectId).collection('image_groups', (ref) => ref.where('images', 'array-contains', imageId)).get();


    /*return this.firestore.collection('images')
      .doc<any>(imageId).get()
      .pipe(switchMap(image => {
        const imageData = image.data();
        if (imageData.groupIds && imageData.groupIds.length) {
          return forkJoin([
            this.firestore.collection('projects').doc(projectId).collection('image_groups').get(),
            of(image)]);
        }
        return of([null, null]);
      }),
        map(([groups, images]) => {
          if (groups && groups.docs.length) {
            return groups.docs[0].data();
          }

          return null;
        }));
        */
  }

  contextAnnotationId: string;
  createAnnotation(
    imageId: string,
    projectId: string,
    polygon: { points: number[], closedType: string },
    tag: string,
    hasContext: boolean,
    sensitive?: number,
    id?: string,
  ): Observable<any> {
    const annotationCollection = this.firestore.collection('annotations', (ref) => ref.where('imageId', '==', imageId));
    const { displayName, uid } = this.getCurrentUser();
    const lastModifiedBy = { displayName, uid };

    const addPolygon = sensitive !== undefined ? { id: id || uuidv4(), polygon, tag, hasContext, sensitive: sensitive, lastModifiedBy: lastModifiedBy } : { id: id || uuidv4(), polygon, tag, hasContext, lastModifiedBy: lastModifiedBy };
    this.contextAnnotationId = addPolygon.id;
    return annotationCollection.get().pipe(switchMap((r: any) => {

      if (!r.docs.length) return fromPromise(this.firestore.collection('annotations').add({
        imageId,
        projectId,
        polygons: [addPolygon]
      }));

      const annotations = r.docs[0];
      // this.contextAnnotationId = annotations.id;

      const polygons = Array.isArray(annotations.data().polygons) ? annotations.data().polygons : [];

      const newPolygons = polygons.filter(x => x.id !== id);
      return fromPromise(annotations.ref.update({
        ...annotations.data(),
        polygons: [
          ...newPolygons,
          addPolygon
        ]
      }));
    }));
  }

  createTemperatureAnnotation(
    imageId: string,
    projectId: string,
    polygon: { points: number[], closedType: string },
    id?: string,
    minTemperature?: number,
    maxTemperature?: number,
    temperature?: number,
  ): Observable<any> {
    const annotationCollection = this.firestore.collection('annotations', (ref) => ref.where('imageId', '==', imageId));
    const { displayName, uid } = this.getCurrentUser();
    const lastModifiedBy = { displayName, uid };

    const addPolygon = { id: id || uuidv4(), polygon, minTemperature, maxTemperature, temperature, lastModifiedBy: lastModifiedBy };
    this.contextAnnotationId = addPolygon.id;
    return annotationCollection.get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return fromPromise(this.firestore.collection('annotations').add({
        imageId,
        projectId,
        thermalPolygons: [addPolygon]
      }));
      const annotations = r.docs[0];
      const polygons = Array.isArray(annotations.data().thermalPolygons) ? annotations.data().thermalPolygons : [];
      const newPolygons = polygons.filter(x => x.id !== id);
      return fromPromise(annotations.ref.update({
        ...annotations.data(),
        thermalPolygons: [
          ...newPolygons,
          addPolygon
        ]
      }));
    }));
  }

  updateAnnotationNarration(imageId: string, projectId: string, narration: string, isGroupNarration: boolean, groupNarration: []) {
    const annotationCollection = this.firestore.collection('annotations', (ref) => ref.where('imageId', '==', imageId));
    const { displayName, email, uid } = this.getCurrentUser();
    const narrationModifiedBy = { displayName, email, uid };
    return annotationCollection.get().pipe(
      switchMap((response) => {
        if (response.docs[0]) {
          return response.docs[0].ref.update({
            narration: narration || '',
            narrationModifiedBy: narrationModifiedBy,
            isGroupNarration: isGroupNarration,
            groupNarration: groupNarration
          });
        } else {
          return annotationCollection.add({
            imageId,
            narration: narration || '',
            projectId: projectId,
            narrationModifiedBy: narrationModifiedBy,
            isGroupNarration: isGroupNarration,
            groupNarration: groupNarration
          })
        }
      })
    )
  }

  removeAnnotation(id: string, imageId: string): Observable<any> {
    const annotationCollection = this.firestore.collection('annotations', (ref) => ref.where('imageId', '==', imageId));

    return annotationCollection.get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return of('');
      const annotations = r.docs[0];
      const polygons = Array.isArray(annotations.data()['polygons']) ? annotations.data()['polygons'] : [];
      const newPolygons = polygons.filter(x => x.id !== id);
      return fromPromise(annotations.ref.update({
        ...annotations.data(),
        polygons: [
          ...newPolygons,
        ]
      }));

    }));
  }

  removeThermalAnnotation(id: string, imageId: string, polygonType): Observable<any> {
    const annotationCollection = this.firestore.collection('annotations', (ref) => ref.where('imageId', '==', imageId));
    return annotationCollection.get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return of('');
      const annotations = r.docs[0];
      const polygons = Array.isArray(annotations.data()[polygonType]) ? annotations.data()[polygonType] : [];
      const newPolygons = polygons.filter(x => x.id !== id);
      return fromPromise(annotations.ref.update({
        ...annotations.data(),
        [polygonType]: [
          ...newPolygons,
        ]
      }));

    }));
  }

  addSeverityLevel(id: string, imageId: string, sensitive: number): Observable<any> {
    const annotationCollection = this.firestore.collection('annotations', (ref) => ref.where('imageId', '==', imageId));
    return annotationCollection.get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return of('');
      const annotations = r.docs[0];
      const polygons = Array.isArray(annotations.data().polygons) ? annotations.data().polygons : [];
      const newPolygons = polygons.filter(x => x.id !== id);
      let addPolygon = polygons.find(x => x.id === id);
      addPolygon.sensitive = sensitive;
      return fromPromise(annotations.ref.update({
        ...annotations.data(),
        polygons: [
          ...newPolygons,
          addPolygon
        ]
      }));
    }));
  }

  addAnnotationNote(
    imageId: string,
    id: string,
    note: string,
    polygon: { points: number[] },
    tag: string,
    polygonType: string
  ): Observable<any> {
    const annotationCollection = this.firestore.collection('annotations', (ref) => ref.where('imageId', '==', imageId));

    const { displayName, email } = this.getCurrentUser();
    const noteCreatedBy = { displayName, email };
    let addPolygon = { id, polygon, tag, note, noteCreatedBy };

    return annotationCollection.get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return fromPromise(this.firestore.collection('annotations').add({
        imageId,
        notes: [addPolygon]
      }));

      const annotations = r.docs[0];

      const polygons = Array.isArray(annotations.data()[polygonType]) ? annotations.data()[polygonType] : [];

      const newPolygons = polygons.filter(x => x.id !== id);
      addPolygon = polygons.find(x => x.id === id);
      addPolygon = { ...addPolygon, note, noteCreatedBy };
      return fromPromise(annotations.ref.update({
        ...annotations.data(),
        [polygonType]: [
          ...newPolygons,
          addPolygon,
        ]
      }));
    }));

  }

  removeTag(id: string, projectId: string, tag: any): Observable<any> {
    const { user } = this.authProcess;

    return this.firestore.collection('tags', (ref) => ref.where('projectId', '==', projectId)).get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return of('');
      const tagCollection = r.docs[0];

      const tags = tagCollection.data().tags;
      let searchTagIdx = tags.findIndex(x => x.id === id);
      if (searchTagIdx === -1) return of('');
      const newTags = tags.filter(x => x.id !== id);
      return fromPromise(tagCollection.ref.update({
        tags: [...newTags]
      }));
    }));
  }


  editTag(id: string, projectId: string, levels: any, tag: any): Observable<any> {
    const { user } = this.authProcess;

    return this.firestore.collection('tags', (ref) => ref.where('projectId', '==', projectId)).get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return of('');
      const tagCollection = r.docs[0];

      const tags = tagCollection.data().tags;
      let searchTagIdx = tags.findIndex(x => x.id === id);
      if (searchTagIdx === -1) return of('');

      const tagUpdate = {
        ...tags[searchTagIdx],
        levels,
        ...(tag.chart && { chart: tag.chart })
      };

      const newTags = tags.filter(x => x.id !== id);
      return fromPromise(tagCollection.ref.update({
        tags: [...newTags, tagUpdate]
      }));
    }));
  }

  updateTagStatus(id: string, projectId: string, tag: any): Observable<any> {
    return this.firestore.collection('tags', (ref) => ref.where('projectId', '==', projectId)).get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return of('');
      const tagCollection = r.docs[0];

      const tags = tagCollection.data().tags;
      let searchTagIdx = tags.findIndex(x => x.id === id);
      if (searchTagIdx === -1) return of('');

      const newTags = tags.filter(x => x.id !== id);
      return fromPromise(tagCollection.ref.update({
        tags: [...newTags, { ...tag }]
      }));
    }));
  }

  setTagColor(id: string, projectId: string, tag: any, color: string) {
    return this.firestore.collection('tags', (ref) => ref.where('projectId', '==', projectId)).get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return of('');
      const tagCollection = r.docs[0];

      const tags = tagCollection.data().tags;
      let searchTagIdx = tags.findIndex(x => x.id === id);
      if (searchTagIdx === -1) return of('');

      const newTags = tags.filter(x => x.id !== id);
      console.log(newTags, tag)
      return fromPromise(tagCollection.ref.update({
        tags: [...newTags, { ...tag, color }]
      }));
    }));
  }

  addTag(tag: string, sensitivity: boolean, projectId: string, aiTag, description: string, levels: any, chart: string): Observable<any> {
    const { user } = this.authProcess;
    return this.firestore.collection('tags', (ref) => ref.where('projectId', '==', projectId)).get()
      .pipe(switchMap((r: any) => {
        const doc = r.docs[0];

        // const activeTags = doc.data().tags.filter((x) => x.status !== 'de-active' && x.status !== 'deleted');
        // const color = this.CONSTANTS.tags_colors[activeTags.length];
        let color;
        if (doc && doc.data().tags) {
          const activeTags = doc.data().tags.filter((x) => x.status !== 'de-active' && x.status !== 'deleted');
          color = this.CONSTANTS.tags_colors[activeTags.length];
        }
        if (!color) {
          color = this.CONSTANTS.tags_colors[Math.floor(Math.random() * this.CONSTANTS.tags_colors.length)]
        }
        const tagData = {
          id: uuidv4(),
          tag,
          sensitivity,
          uuid: user.uid,
          status: 'active',
          created_at: new Date(),
          color,
          aiTag,
          description,
          levels,
          ...(chart && { chart: chart })
        };

        if (r.docs.length === 0) {
          return fromPromise(this.firestore.collection('tags').add({
            projectId,
            tags: [tagData]
          }))
        }

        const tags = doc.data().tags;
        return fromPromise(doc.ref.update({
          tags: [...tags, tagData]
        }));
      }));
  }

  getImageAnnotations$<T extends any>(imageId: string): Observable<T> {
    if (!imageId) { console.error('Error: imageId is absent!'); return of() };
    return this.firestore
      .collection<T>('annotations', (ref) => ref.where('imageId', '==', imageId))
      .valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'getImageAnnotations$'),
        map(r => {
          return r.length > 0 ? r[0] : null
        })
      );
  }

  getImageAnnotations<T extends any>(imageId: string): Observable<any> {
    return this.firestore
      .collection<T>('annotations', (ref) => ref.where('imageId', '==', imageId))
      .get().pipe(
        map((image: any) => {
          return image.docs.length > 0 ? { id: image.docs[0].id, ...image.docs[0].data() } : null

        })
      )
  }

  deleteDoc(path): Observable<any> {
    return this.httpClient.post(
      `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/deleteDoc`,
      {
        docPath: path,
      }
    );
  }

  markFolderDeleted(item, projectId: string) {
    this.deleteImages(item.data);
    return this.firestore.doc(item.data.path).update({
      deletedDate: new Date().toISOString(),
      deletedBy: this.getCurrentUser().uid
    })
  }

  deleteImages(item) {
    const query = this.firestore
      .collection('images', (ref) => ref.where('folderPath', '==', item.path))
      .get();
    query.subscribe((querySnapshot) => {
      const selectedImages = querySnapshot.docs.map(selectedImage => selectedImage.id);
      if (selectedImages.length) {
        selectedImages.map((id) =>
          this.markFolderImageDeleted(id)
        )
      }
    })
    item.children.forEach(element => {
      this.deleteImages(element);
    });
  }

  allReportsData() {
    return this.storage.ref('reports').listAll();
  }

  downloadPdfFile(id: string, name: string): void {
    this.storage
      .ref(`reports/${id}.pdf`)
      .getDownloadURL()
      .subscribe((url) => {
        this.httpClient
          .get(url, {
            responseType: 'blob',
            headers: { Accept: 'application/pdf' },
          })
          .subscribe((blob) => {
            saveAs(blob, `${name}.pdf`);
          });
      });
  }

  downloadDocxFile(id: string, name: string): void {
    this.storage
      .ref(`reports/${id}.docx`)
      .getDownloadURL()
      .subscribe((url) => {
        this.httpClient
          .get(url, {
            responseType: 'blob',
            headers: { Accept: 'application/pdf' },
          })
          .subscribe((blob) => {
            saveAs(blob, `${name}.docx`);
          });
      });
  }

  removeReport(id): Observable<any> {
    return fromPromise(
      this.firestore.collection('reports').doc(id).delete()
    );
  }

  getCurrentUser(): any {
    const { user } = this.authProcess;
    return user;
  }

  setUserCompany(companyName: string, industry: string): Observable<any> {
    const { user } = this.authProcess;
    return fromPromise(
      this.firestore.collection('users').doc(user.uid).update({
        companyName,
        industry,
      })
    );
  }

  getFoldersOf(docRef, path, result): void {
    result.children = [];
    docRef
      .collection('folders')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (!doc.data().deletedDate) {
            result.children.push({
              id: doc.id,
              path: `${path}/folders/${doc.id}`,
              doc: doc.data(),
              name: doc.data().name,
              breadcrumb: {
                name: `${result.breadcrumb.name}/${doc.data().name}`,
              },
            });

          }
        });

        result.children = result.children.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        let i = 0;
        querySnapshot.forEach((doc) => {
          if (result.children[i]) {
            this.getFoldersOf(
              doc.ref,
              `${path}/folders/${doc.id}`,
              result.children[i]
            );
            i++;
          }
        });
      });
  }

  getActiveFolders(folderPath): Observable<any> {
    return this.firestore
      .doc(folderPath)
      .get()
  }


  finalReport(url: string, reportId) {
    const { user } = this.authProcess;
    this.firestore.collection('reports').doc(reportId).update(
      {
        finalReportData: {
          createdBy: user.uid,
          pdf: url,
          status: 'uploaded',
          createdDate: new Date(),
          freeze: false
        },
        freeze: true
      })
  }

  removeFinalReport(reportId) {
    return this.firestore.collection('reports').doc(reportId).update(
      {
        finalReportData: null
      })
  }

  createReport(reportData, initialsData: IInitialsData, status: string): Observable<any> {
    const { user } = this.authProcess;
    if (initialsData) {
      reportData['initialsData'] = initialsData;
    }
    return fromPromise(
      this.firestore.collection('reports').add({
        uid: user.uid,
        createdDate: new Date(),
        reportData,
        reportLocation: {
          status: status || 'in-progress'
        },
        freeze: false
      })
    );
  }

  editReport(id: string, reportData, initialsData: IInitialsData, status: string): Observable<any> {
    const reportRef = this.firestore.collection('reports').doc(id);
    // return reportRef.valueChanges().pipe(take(1), switchMap((item: any) => {
    if (initialsData) {
      reportData['initialsData'] = initialsData;
    } else {
      reportData['initialsData'] = {};
    }
    return fromPromise(
      reportRef.update({
        editedDate: new Date(),
        reportData: reportData,
        reportLocation: {
          status: status || 'in-progress'
        },
      })
    );
    // }));
  }

  changeReportFreeze(id: string, freeze: boolean): Observable<any> {
    const reportRef = this.firestore.collection('reports').doc(id);
    return fromPromise(
      reportRef.update({ freeze })
    );
  }

  changeFinalReportFreeze(id: string, freeze: boolean): Observable<any> {
    const reportRef = this.firestore.collection('reports').doc(id);
    if (freeze) {
      return fromPromise(
        reportRef.update({ ["finalReportData.freeze"]: freeze, freeze: freeze })
      );
    } else {
      return fromPromise(
        reportRef.update({ ["finalReportData.freeze"]: freeze })
      );
    }
  }

  updateReportGeneratedDate(reportId: string): Observable<any> {
    const reportRef = this.firestore.collection('reports').doc(reportId);
    return (reportRef as any).get().pipe(switchMap((doc: any) => {
      if (doc.exists) {
        reportRef.update({
          reportData: {
            ...doc.data().reportData,
            reportGenerated: new Date().toISOString()
          }
        });
      }
      return of();
    }));
  }

  updateReportStatus(reportId: string, status: string): Observable<any> {
    const reportRef = this.firestore.collection('reports').doc(reportId);
    return (reportRef as any).get().pipe(switchMap((doc: any) => {
      if (doc.exists) {
        reportRef.update({
          reportLocation: {
            status: status
          }
        });
      }
      return of();
    }));
  }

  deleteSelectedInitialsIndex$(idx: number): Observable<any> {
    const { user } = this.authProcess;
    const reportsRef = this.firestore.collection('reports', (ref) => ref.where('uid', '==', user.uid));
    return reportsRef.get().pipe(switchMap((response: any) => {
      response.docs.forEach(doc => {
        if (doc.data().reportData &&
          (doc.data().reportData.selectedInitialsIndex || doc.data().reportData.selectedInitialsIndex === 0) &&
          doc.data().reportData.selectedInitialsIndex === idx
        ) {
          reportsRef.doc(doc.id).update({
            reportData: {
              ...doc.data().reportData,
              selectedInitialsIndex: null
            }
          });
        }
      });
      return of(true);
    }));
  }

  sendReportForGeneration(reportId: string): Observable<any> {
    return this.httpClient.get(
      `${environment.baseAPIUrl}/create-report?report_id=${reportId}`
    );
  }

  addImageGroup(groupName: string, projectId: string): Observable<any> {
    return fromPromise(
      this.firestore.collection('projects').doc(projectId)
        .collection('image_groups').add({
          groupName,
          projectId,
        })
    );
  }

  updateImageGroup(groupId: string, groupName: string, projectId: string): Observable<any> {
    return fromPromise(
      this.firestore.collection('projects').doc(projectId)
        .collection('image_groups').doc(groupId).update({
          groupName
        })
    );
  }
  getImageRelation$(projectId: string, parentImagePath: string): Observable<any> {
    return this.firestore
      .collection('projects')
      .doc<Project>(projectId)
      .collection('relations', (ref) => ref.where('parentImagePath', '==', parentImagePath))
      .valueChanges().pipe(handleFirebaseError(this.auth, 'getImageRelation$'));
  }

  getImageRelationForChild$(projectId: string, childImagePath: string): Observable<any> {
    return this.firestore
      .collection('projects')
      .doc<Project>(projectId)
      .collection('relations', (ref) => ref.where('childImagePath', '==', childImagePath))
      .valueChanges().pipe(handleFirebaseError(this.auth, 'getImageRelationForChild$'));
  }

  getProjectRelations$(projectId: string): Observable<any> {
    return this.firestore
      .collection('projects')
      .doc<Project>(projectId)
      .collection('relations')
      .valueChanges().pipe(handleFirebaseError(this.auth, 'getProjectRelations$'));
  }


  // ====================================
  // get ProjectRelations by scrolling
  latestRelationEntry: any; // - for next scroll
  relationsLimit: number = 6;
  getProjectRelationsAll$(projectId): Observable<any[]> {
    return this.getProjectRelationsCollection(projectId)
  }


  getProjectRelationsCollection(projectId, queryFn?): Observable<any[]> {
    return this.firestore
      .collection('projects')
      .doc<Project>(projectId)
      .collection('relations', queryFn).snapshotChanges().pipe(
        handleFirebaseError(this.auth, 'getProjectRelationsCollection'),
        map(actions => {
          this.latestRelationEntry = actions.length >= this.relationsLimit ? actions[actions.length - 1].payload.doc : null;
          return actions.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });

        }),
        /* tap(data => {
           this.latestRelationEntry = data[data.length - 1] ? data[data.length - 1].doc : null;
         })*/
      );
  }

  getProjectRelationsFirst$(projectId): Observable<any[]> {
    return this.getProjectRelationsCollection(projectId, ref => ref.limit(this.relationsLimit))
  }

  getProjectRelationsNext$(projectId): Observable<any[]> {
    if (!this.latestRelationEntry) { return of([]); }
    return this.getProjectRelationsCollection(
      projectId,
      ref => ref.startAfter(this.latestRelationEntry).limit(this.relationsLimit)
    )
  }

  getImageRelationFirst$(projectId: string, pathName: string, imagePath: string): Observable<any[]> {
    return this.getProjectRelationsCollection(projectId,
      ref => ref.where(pathName, '==', imagePath).limit(this.relationsLimit)
    )
  }

  getImageRelationNext$(projectId: string, pathName: string, imagePath: string): Observable<any[]> {
    return this.getProjectRelationsCollection(projectId,
      ref => ref.where(pathName, '==', imagePath).startAfter(this.latestRelationEntry).limit(this.relationsLimit)
    )
  }

  getAllImagesRelationsFirst$(projectId: string): Observable<any[]> {
    return this.getProjectRelationsCollection(projectId,
      ref => ref.limit(this.relationsLimit)
    )
  }

  getAllImagesRelationsNext$(projectId: string): Observable<any[]> {
    return this.getProjectRelationsCollection(projectId,
      ref => ref.startAfter(this.latestRelationEntry).limit(this.relationsLimit)
    )
  }

  // =====================

  getImagesRelations$(projectId: string): Observable<any> {
    return this.firestore
      .collection('projects')
      .doc<Project>(projectId)
      .collection('relations', (ref) => ref)
      .valueChanges().pipe(handleFirebaseError(this.auth, 'getImagesRelations$'));
  }

  addImageRelation(projectId, parentImagePath: string, childImagePath: string, childLocationInParent: any, imageId: string): Observable<any> {
    const { user } = this.authProcess;
    const addObj = {
      parentImagePath,
      childImagePath,
      childLocationInParent,
      createdBy: user.uid,
      annotationId: this.contextAnnotationId,
      imageId
    }
    this.uiService.updateContextsEvent$.next(addObj);
    return fromPromise(this.firestore.collection('projects').doc<Project>(projectId)
      .collection('relations').add(addObj));
  }

  removeImageRelation(projectId, parentImagePath, childImagePath): Observable<any> {
    return this.firestore.collection('projects').doc<Project>(projectId)
      .collection('relations', (ref) =>
        ref
          .where('parentImagePath', '==', parentImagePath)
          .where('childImagePath', '==', childImagePath)).get()
      .pipe(switchMap(r => {
        return forkJoin(r.docs.map(d => d.ref.delete()));
      }));
  }

  removeImageRelationByAnnotationId(projectId: string, annotationId: string) {
    return this.firestore.collection('projects').doc<Project>(projectId)
      .collection('relations', (ref) => ref.where('annotationId', '==', annotationId)).get()
      .pipe(switchMap(r => {
        return forkJoin(r.docs.map(d => d.ref.delete()));
      }));
  }

  removeImageGroup(id: string, projectId: string): Observable<any> {
    return fromPromise(
      this.firestore.collection('projects').doc(projectId).collection('image_groups').doc(id).delete()
    );
  }

  addTemplate(title: string, fileName: string, projectId: string): Observable<any> {
    const { user } = this.authProcess;
    return this.storage
      .ref(`templates/${fileName}`)
      .getDownloadURL()
      .pipe(
        map((url) => {
          return fromPromise(
            this.firestore.collection('reports').add({
              title,
              isTemplate: true,
              projectId: projectId,
              createdAt: new Date(),
              createdBy: user.uid,
              docxURl: url,
            })
          );
        })
      );
  }

  removeTemplate(id): Observable<any> {
    return fromPromise(this.firestore.collection('reports').doc(id).delete());
  }

  reportSnapshotChanges(reportId: string): Observable<any> {
    return this.firestore.collection('reports').doc(reportId).snapshotChanges().pipe(
      handleFirebaseError(this.auth, 'reportSnapshotChanges'),
    );
  }

  updateCurrentUserEmail(email): Promise<any> {
    return this.getCurrentUser().updateEmail(email);
  }

  updateCurrentUserProfile(data): Promise<any> {
    return this.getCurrentUser().updateProfile(data);
  }

  reauthenticate = (currentPassword) => {
    var user = this.getCurrentUser();
    var cred = firebase.auth.EmailAuthProvider.credential(
      user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }

  getReauthenticateUser(currentPassword): Promise<any> {
    return this.reauthenticate(currentPassword);
  }

  updateCurrentUserPassword(password): Promise<any> {
    return this.getCurrentUser().updatePassword(password);
  }

  updateUser(data): Promise<any> {
    if (!this.getCurrentUser().uid) {
      return;
    }
    return this.firestore.collection(`users`).doc(this.getCurrentUser().uid).update(data);
  }

  addIndustry(industry: string, type): Promise<any> {
    return this.firestore.collection(`users`).doc(this.getCurrentUser().uid).update({
      industries: type ? firebase.firestore.FieldValue.arrayUnion(industry) : [industry]
    });
  }

  deleteIndustry(industry: string): Promise<any> {
    return this.firestore.collection(`users`).doc(this.getCurrentUser().uid).update({
      industries: firebase.firestore.FieldValue.arrayRemove(industry)
    });
  }

  updateProfilePic(file: IFile) {
    const ref = this.storage.ref('profile').child(uuidv4());
    if (file.file) {
      return from(ref.put(file.file)).pipe(switchMap((response) => {
        return from(response['ref'].getDownloadURL()).pipe(switchMap(downloadUrl => {
          return of({
            link: downloadUrl,
            bucketName: response['ref'].name,
            name: file.file.name
          });
        }));
      }));
    } else {
      return of(null);
    }
  }

  valueChangesIndustries(): Observable<any> {
    return this.firestore
      .collection('users').doc(this.getCurrentUser().uid)
      .valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'valueChangesIndustries')
      )
  }

  getHistory(imageId): Observable<any> {
    return this.firestore.collection(`history`, (ref) =>
      ref.where('imageId', '==', imageId)
    ).get();
  }

  addHistory(imageIds: string[], projectId, action: string, type: string, reference) {
    const historyCollection = this.firestore.collection('history');
    imageIds.forEach(imgId => {
      historyCollection.add({
        imageId: imgId,
        userId: this.getCurrentUser().uid,
        date: new Date(),
        projectId: projectId,
        action: action,
        type: type,
        reference: reference
      })
    });

  }

  verifyDoc(file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(
      `${environment.baseAPIUrl}/verify-docx`,
      formData
    );
  }

  /*incrementDomainContext(type, count) {
    const domainCollection = this.firestore.collection('domain').doc(this.DomainName);
    const param = { [type]: firebase.firestore.FieldValue.increment(count) }
    domainCollection.update(param)
 }
*/

  /** Listeners to check domain of the user */
  userSubscription = new Subscription();
  domainUsersSubscription = new Subscription();
  domainSubscription = new Subscription();
  getDomainControl(currentUser) {
    this.userSubscription.unsubscribe();
    this.userSubscription = this.getUser$(currentUser.uid).subscribe(user => {
      this.currentUserData = user;
      if (user && user.domain && (!this.USED_DATA || !this.DOMAIN_CONTROL)) {
        this.domainUsersSubscription.unsubscribe();
        this.domainUsersSubscription = this.firestore.collection('domain-users').doc(user.domain).valueChanges({ idField: 'id' }).subscribe((usedData: any) => {
          this.USED_DATA = usedData;
        });
        this.domainSubscription.unsubscribe();
        this.domainSubscription = this.firestore
          .collection('domain_control')
          .doc(user.domain)
          .valueChanges({ idField: 'id' })
          .subscribe((domain) => {
            this.DOMAIN_CONTROL = domain;
          });
      }

      if (user && user.providerId && !user.isExternalUser && !user.domain && user.providerId == 'google.com') {
        // All the Google Sign-in user will be act as a external user
        this.updateUser({ isExternalUser: true });
      }
    });
  }

  valueChangesProjectInitials(projectId: string,): Observable<any> {
    return this.firestore
      .collection('projects').doc(projectId).collection('initials', (ref) =>
        ref
          .orderBy('createdTime')
      ).valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'valueChangesProjectInitials')
      )
  }

  sendReportMail(projectPeoples, report, isFinalReport, assetName: string): Promise<any> {
    var user = this.getCurrentUser();
    projectPeoples.forEach(people => {
      if (people.email != user.email) {
        this.firestore.collection('mail').add({
          to: people.email,
          message: {
            subject: `
            ${environment.appName}: Report Freezed for ${assetName} : ${report.reportData.name} by user  ${user.displayName}`,
            html: `
            Dear ${user.displayName},
            <br><br>
            <b>${user.displayName}</b> has frozen the for <b> ${assetName} : ${report.reportData.name}</b> on ${new Date().toDateString()}. 
            <br>
        You are receiving a copy of the Frozen report as you are a member of the same project. If you wish to make any changes to the report you will need to ask ${user.displayName} to do so, or you may need to go to DeepDIVE  <a href="https://${environment.firebase.authDomain}">link<a>
         and create a new report with the changes you would like to make. 
         <br>
         Attached the pdf of the report
            ` + this.emailFooter(),
            attachments: [
              {
                path: isFinalReport ? `${report.finalReportData?.pdf}` : `${report.reportLocation?.pdf}`,
              },
            ]
          },
        })
      }

    })
    if (isFinalReport) {
      return this.firestore.collection('reports').doc(report._id).update({
        ['finalReportData.emailSend']: true,
        ['finalReportData.emailSendTime']: new Date()
      })
    } else {
      return this.firestore.collection('reports').doc(report._id).update({
        emailSend: true,
        emailSendTime: new Date()
      })
    }
  }

  getAssets(): Observable<any> {
    const { user } = this.authProcess;
    return this.firestore.collection('assets', (ref) =>
      ref.where(`users`, 'array-contains', user.uid)
    ).valueChanges({ idField: 'id' }).pipe(
      handleFirebaseError(this.auth, 'getAssets')
    );
  }

  getAssetsById(id): Observable<any> {
    return this.firestore.collection('assets').doc(id).get();
  }

  getCompanyAssets(): Observable<any> {

    const { user } = this.authProcess;
    return this.firestore.collection('assets-company', (ref) =>
      ref.where('users', 'array-contains', user.uid)
    ).valueChanges({ idField: 'id' }).pipe(
      handleFirebaseError(this.auth, 'getCompanyAssets')
    );
  }

  uploadTechnicalDrawing(file: IFile) {
    const ref = this.storage.ref('technical-drawing/').child(uuidv4() + "." + file.name.split('.').pop());
    if (file) {
      return from(ref.put(file)).pipe(switchMap((response) => {
        return from(response['ref'].getDownloadURL()).pipe(switchMap(downloadUrl => {
          return of({
            link: downloadUrl,
            bucketName: response['ref'].name,
            name: file.name
          });
        }));
      }));
    } else {
      throw Error('File is undefined');
    }
  }

  uploadAssetsImage(file: IFile) {
    const ref = this.storage.ref('assets').child(uuidv4());
    if (file) {
      return from(ref.put(file)).pipe(switchMap((response) => {
        return from(response['ref'].getDownloadURL()).pipe(switchMap(downloadUrl => {
          return of({
            link: downloadUrl,
            bucketName: response['ref'].name,
            name: file.name
          });
        }));
      }));
    } else {
      throw Error('File is undefined');
    }
  }

  createAssetCompany(companyName: string): Observable<any> {
    const { user } = this.authProcess;
    return fromPromise(
      this.firestore.collection(`assets-company`).add({
        createdBy: user.uid,
        companyName: companyName,
        createdDate: new Date(),
        users: [user.uid]
      })
    );
  }

  createCampaign(campaignName: string, companyId: string, assets: [], startDate: string, endDate: string): Observable<any> {
    const { user } = this.authProcess;
    return fromPromise(
      this.firestore.collection(`campaign`).add({
        createdBy: user.uid,
        createdDate: new Date(),
        campaignName: campaignName,
        assets: assets,
        companyId: companyId,
        startDate: startDate,
        endDate: endDate,
        users: [user.uid],
      })
    );
  }

  getCampaignById(id): Observable<any> {
    return this.firestore.collection('campaign').doc(id).get();
  }
  addOrEditAssets(params): Observable<any> {
    const { user } = this.authProcess;
    if (params.id) {
      params["createdBy"] = params["createdBy"] || user.uid;
      return fromPromise(
        this.firestore.collection(`assets`).doc(params.id).update({ ...params, lastUpdatedBy: user.uid })
      );
    } else {
      params["domain"] = this.DOMAIN_CONTROL?.id || null;
      params["users"] = [user.uid];
      this.firestore.collection(`assets`)
      return fromPromise(
        this.firestore.collection(`assets`).add({ ...params, createdBy: user.uid })
      );
    }

  }

  changeAssetOwner(assetId, assetOwnerId, domain: string): Observable<any> {
    return fromPromise(
      this.firestore.collection(`assets`).doc(assetId).update({ createdBy: assetOwnerId, transferBy: this.currentUser.uid, domain: domain })
    );
  }

  labelSelection(assetId, baseModel): Observable<any> {
    return fromPromise(
      this.firestore.collection(`assets`).doc(assetId).update({ baseModel: baseModel })
    );
  }


  getTagNmber(): Observable<any> {
    return this.firestore.collection('assets').get();
  }

  getAllUserAssets(): Observable<any> {
    const { user } = this.authProcess;
    return this.firestore.collection('assets', (ref) =>
      ref.where(`users`, 'array-contains', user.uid)
    ).get().pipe(
      handleFirebaseError(this.auth, 'getAllUserAssets'),
      map(r => {
        return r.docs.length > 0 ? r.docs.map(o => o.data()) : null
      })
    );
  }

  linkAssetToProject(projectId, assetId): Observable<any> {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    return this.firestore.collection('projects').doc(projectId)
      .get().pipe(
        handleFirebaseError(this.auth, 'linkAssetToProject'),
        switchMap(r => {
          return forkJoin([
            of(r),
            this.firestore.collection('assets').doc(assetId).get()
          ]);
        }),
        switchMap(([projects, assets]: any) => {
          const users = [];
          Object.keys(projects.data().people).map(function (key) {
            users.push(key)
          })
          let aseetUsers: any = assets.data().users;
          aseetUsers = aseetUsers.concat(users).filter(onlyUnique);

          return forkJoin([
            of([projects, assets, aseetUsers, users]),
            this.firestore.collection('assets-company').doc(assets.data().companyId).get()
          ]);

        }),
        map(([assetData, company]: any) => {
          let aseetCompanyUsers: any[] = company.data().users;
          aseetCompanyUsers = aseetCompanyUsers.concat(assetData[3]).filter(onlyUnique);
          return of([this.firestore.collection('projects').doc(assetData[0].id).update({
            assetId: assetId
          }),
          this.firestore.collection('assets').doc(assetData[1].id).update({
            users: assetData[2]
          }),
          this.firestore.collection('assets-company').doc(company.id).update({
            users: aseetCompanyUsers
          }),

          ]);

        })
      );
  }

  getCompanyList(): Observable<any> {
    const { user } = this.authProcess;
    return this.firestore.collection('assets-company', (ref) =>
      ref.where(`users`, 'array-contains', user.uid)
    ).get().pipe(
      handleFirebaseError(this.auth, 'getCompanyList')
    );
  }

  getAssetList(): Observable<any> {
    const { user } = this.authProcess;
    return this.firestore.collection('assets', (ref) =>
      ref.where(`users`, 'array-contains', user.uid)
    ).get().pipe(
      handleFirebaseError(this.auth, 'getAssetList')
    );
  }


  getDefectData(
    projectId: string,
  ): Observable<any> {
    return combineLatest(
      this.firestore
        .collection(`projects`).doc(projectId).get(),
      this.firestore
        .collection(`images`, (ref) =>
          ref.where('projectId', '==', projectId)
            .where('deleted', '==', false)).get()
    ).pipe(
      mergeMap(([project, images]: any) => {
        const imageIds = images.docs.map(o => o.id);
        let batches: any = [];

        while (imageIds.length) {
          const batch = imageIds.splice(0, 10);
          batches = batches.concat(...[this.firestore
            .collection(`annotations`, (ref) =>
              ref.where('imageId', 'in', [...batch])
            )
            .valueChanges({ idField: '_id' }).pipe(
              handleFirebaseError(this.auth, 'getDefectData')
            )]);
        }
        if (batches.length === 0) {
          return of(null);
        }
        return combineLatest(
          batches,
        ).pipe(
          map((batch: any) => {
            const annotations = batch.flat();
            let defects = [];
            let dataDefects = {};
            images.docs.forEach((image, i) => {
              let filter = annotations.filter(o => o.imageId == image.id)[0];
              if (filter && filter.polygons && filter.polygons.length > 0) {
                let object = {};
                const data = image.data();
                object['url'] = getPathFromUrl(data.fileUrl);
                object['imageId'] = image.id;
                if (filter.narration) {
                  object['narration'] = filter.narration;
                }
                object["defectInformation"] = [];

                filter.polygons.forEach(polygons => {
                  let polygon = {};
                  if (polygons.tag != 'context') {
                    polygon['defectType'] = polygons.tag;
                    if (polygons.sensitive) {
                      polygon['severity'] = getSeverity(polygons.sensitive);
                    }
                    if (polygons.note) {
                      polygon['note'] = polygons.note;
                    }
                    polygon['points'] = polygons.polygon.points;
                    object["defectInformation"].push({
                      polygon: polygon
                    })
                  }
                });
                defects.push(object);
              }
            });
            dataDefects = {
              "projectId": projectId,
              "projectInfo": {
                "name": project.data().name,
                "createdDate": project.data().createdDate?.toDate()
              },

              "defects": defects
            }
            if (project.data().inspectionDate) {
              dataDefects["projectInfo"]["dateOfInspection"] = project.data().inspectionDate
            }
            return {
              project: project.data(),
              json: dataDefects
            }
          }

          )
        );
      })
    );
    function getSeverity(value) {
      if (value == 0) {
        return 'low';
      }
      else if (value == 1) {
        return 'medium';
      }
      else {
        return 'high';
      }
    }
    function getPathFromUrl(url) {
      return url.split("?")[0];
    }
  }

  fileUpload(project, json) {
    const { user } = this.authProcess;
    var jsonString = JSON.stringify(json);
    var blob = new Blob([jsonString], { type: "application/json" })
    const ref = this.storage.ref('push-data').child(uuidv4() + ".json");
    return from(ref.put(blob)).pipe(switchMap((response) => {
      return from(response['ref'].getDownloadURL()).pipe(switchMap(downloadUrl => {
        return this.firestore.collection('mail').add({
          to: [user.email],
          message: {
            subject: `${project.name}  data`,
            attachments: [
              {
                path: downloadUrl,
              },
            ]
          },

        })
      }));
    }));
  }




  pageSize = 100;

  getBatchProjectImages$(projectId: string,): Observable<any> {
    return this.firestore
      .collection('images', (ref) => ref.where('projectId', '==', projectId)
        .where('deleted', '==', false))
      .valueChanges({ idField: 'id' }).pipe(takeUntil(this.cancelFetchProjectImage$),)
  }


  async deleteAnnotationById(id) {
    return this.firestore
      .collection('annotations').doc(id).delete()

  }




  cancelFetch(): void {
    this.cancelFetchProjectAnnotations$.next();
    this.cancelFetchProjectAnnotations$.complete();
  }

  getBatchProjectAnnotationsNew$(projectId: string): Observable<any[]> {
    const query = this.firestore
      .collection('annotations', (ref) => ref.where('projectId', '==', projectId)
        .limit(this.batchSize))
      .valueChanges({ idField: '_id' })
    return query.pipe(
      take(1),
      switchMap((initialData) => {
        let lastDocumentId = initialData[initialData.length - 1]?._id;

        const remainingQueries: Observable<any>[] = [];

        for (let i = 0; i < initialData.length; i += this.batchSize) {
          const batch = initialData.slice(i, i + this.batchSize);
          remainingQueries.push(this.processBatch(projectId, lastDocumentId, batch));
          lastDocumentId = batch[batch.length - 1]?._id;
        }

        return merge(...remainingQueries);
      })
    );
  }

  // Method to handle processing each batch
  private processBatch(projectId: string, startAfter: string, batch: any[]): Observable<any> {
    if (batch.length === 0) {
      return of([]); // Return an empty observable if the batch is empty
    }

    const query = this.firestore
      .collection('annotations', (ref) =>
        ref.orderBy(firebase.firestore.FieldPath.documentId()) // Order by document ID
          .where('projectId', '==', projectId)
          .startAfter(startAfter)
          .limit(this.batchSize)
      )
      .valueChanges({ idField: '_id' });

    return query.pipe(
      switchMap((snapshot) => from(snapshot)),
      map((doc: any) => (doc))
    );
  }

  public batchSize = 500;
  getBatchProjectAnnotations$(projectId: string): Observable<any> {
    // return this.firestore
    //   .collection(`annotations`, (ref) =>
    //     ref.where('projectId', '==', projectId)
    //   )
    //   .valueChanges().pipe(takeUntil(this.cancelFetchProjectAnnotations$)
    //   )


    return this.httpClient.post(
      // `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/getProjectAnnotations`,{projectId:projectId}
      `${environment.fetchAnnotations}get_project_annotations/`, { projectID: projectId }
    )
  }


  // For fast data
  getBatchImageAnnotations(imageIds: string[]): Observable<any> {
    let batches: any = [];
    while (imageIds.length) {
      const batch = imageIds.splice(0, 10);
      batches = batches.concat(...[this.firestore
        .collection(`annotations`, (ref) =>
          ref.where('imageId', 'in', [...batch])
        )
        .valueChanges({ idField: '_id' }).pipe(
          handleFirebaseError(this.auth, 'getBatchImageAnnotations')
        )]);
    }
    return combineLatest(
      batches
    ).pipe(
      map((out: any) => {
        return out.flat()
      }
      )
    );
  }

  updateVerifiedImageAnnotation(imageId: string): Observable<any> {
    const annotationCollection = this.firestore.collection('annotations', (ref) => ref.where('imageId', '==', imageId));
    return annotationCollection.get().pipe(
      switchMap((response) => {
        if (response.docs[0]) {
          return response.docs[0].ref.update({
            verifiedAnnotations: true
          });
        } else {
          return annotationCollection.add({
            imageId,
            verifiedAnnotations: true
          })
        }
      })
    )
  }

  changeRole(projectId: string, userId: string, newRole: string, readonly: boolean = false): Observable<any> {
    const temp = `people.${userId}`;
    const update = {};
    update[temp] = {
      role: newRole,
      readonly: readonly
    };
    return fromPromise(
      this.firestore.collection('projects').doc(projectId).update(update)
    );
  }


  reportReviewed(id: string, type: string, userId: string,): Observable<any> {
    const reportRef = this.firestore.collection('reports').doc(id);
    return fromPromise(
      reportRef.update({
        [type]: firebase.firestore.FieldValue.arrayUnion({
          id: userId,
          time: new Date()
        })
      })
    );
  }

  getReportById(reportId): Observable<any> {
    // const { user } = this.authProcess;
    return this.firestore
      .collection(`reports`).doc(reportId).valueChanges({ idField: '_id' }).pipe(
        handleFirebaseError(this.auth, 'getReportById')
      );
  }


  // update firebase functions 
  // updateAllReports() {
  //   return this.firestore
  //       .collection(`report_data`).valueChanges({ idField: '_id' }).pipe(
  //         concatMap((response: any[]) => {
  //           response.forEach(item => {
  //             if (item.initialsData) {
  //               const obj = {
  //                 ...item,
  //                 initialsData: {
  //                   ...item.initialsData,
  //                   contractNumber: item.initialsData.contactNumber || item.initialsData.contractNumber || ''
  //                 }
  //               }
  //               if (obj && obj.initialsData && obj.initialsData.contactNumber === '') {
  //                 console.log('999delete');
  //                 delete obj.initialsData.contactNumber;
  //               }
  //               // this.firestore.collection('report_data').add(obj).then(() => {
  //               //   fromPromise(
  //               //     this.firestore.collection('report_data').doc(item._id).delete()
  //               //   );
  //               // });
  //               console.log(444, obj.initialsData);
  //               this.editReport(item.nodes, item._id, item.name, item.projectId, item.templateId, obj.initialsData).subscribe();
  //             }
  //           });
  //           return of(response);
  //         })
  //       );
  // }
  inspectionSchedule(id, params): Observable<any> {
    return fromPromise(
      this.firestore.collection(`assets`).doc(id).update({
        inspection: params
      })
    );
  }

  addImageTimeline(projectId, keyImageId: string, valueImageId: string, title: string, keyProjectInspectionDate, valueProjectInspectionDate): Observable<any> {
    const { user } = this.authProcess;
    const addObj = {
      createdBy: user.uid,
      keyImageId: keyImageId,
      valueImageId: valueImageId,
      title: title,
      keyProjectInspectionDate: new Date(keyProjectInspectionDate),
      valueProjectInspectionDate: new Date(valueProjectInspectionDate)
    }
    const timelineCollection = this.firestore.collection('projects').doc<Project>(projectId).collection('timelines', (ref) =>
      ref.where('keyImageId', '==', keyImageId)
        .where('valueImageId', '==', valueImageId)
    )
    return timelineCollection.get().pipe(
      switchMap((response) => {
        if (response.docs[0]) {
          return this.firestore.collection('projects').doc<Project>(projectId)
            .collection('timelines').doc(response.docs[0].id).update(addObj);

        } else {
          return this.firestore.collection('projects').doc<Project>(projectId)
            .collection('timelines').add(addObj);
        }
      })
    )

  }

  // ====================================
  getProjectTimelinesCollection(projectId): Observable<any[]> {
    return this.firestore
      .collection('projects')
      .doc<Project>(projectId)
      .collection('timelines').snapshotChanges().pipe(
        handleFirebaseError(this.auth, 'getProjectTimelinesCollection'),
        map(actions => {
          return actions.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }),
        tap(data => { })
      );
  }


  getAllImagesTimelines$(projectId: string): Observable<any[]> {
    return this.getProjectTimelinesCollection(projectId)
  }

  getAIEngine(projectId): Observable<any> {
    return this.firestore
      .collection(`ai_engine`, (ref) =>
        ref.where('projectId', '==', projectId)
      ).valueChanges({ idField: '_id' }).pipe(
        handleFirebaseError(this.auth, 'getAIEngine')
      );
  }

  updateSummary(projectId) {
    return this.httpClient.post(
      `${environment.baseURL}/project-summary/`, { 'projectId': projectId });
  }

  getVersion(): Observable<any> {
    return this.firestore
      .collection(`version_control`).valueChanges({ idField: '_id' }).pipe(
        handleFirebaseError(this.auth, 'getVersion')
      );
  }

  async deleteAnnotations(projectId: string): Promise<any> {
    const me = this;
    const db = this.firestore.firestore;
    const batch = db.batch();
    async function getAnnotations(projectId: string) {
      const annotations: any = await db.collection('annotations')
        .where('projectId', '==', projectId)
        .get();
      return annotations.docs;
    }

    async function removeAnnotations(imageId: string) {
      const annotations: any =
        await db.collection('annotations')
          .where('imageId', '==', imageId)
          .get();
      return annotations.docs;
    }

    async function removeAIEngineData(projectId: string) {
      const engine: any =

        await db.collection('ai_engine')
          .where('projectId', '==', projectId)
          .get();
      return engine.docs;
    }
    const anotations: any = await getAnnotations(projectId);

    let currentBatch = db.batch();
    let currentBatchSize = 0;
    // prepare the batch
    const batches = [currentBatch];
    // add each doc's deletion to the batch
    anotations.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = db.batch();
        batches.push(currentBatch);
        currentBatchSize = 1;
      }
      currentBatch.delete(doc.ref);
    });
    // commit the changes
    await Promise.all(batches.map(batch => batch.commit()));
    // const AIEngine = await removeAIEngineData(projectId);
    // if (AIEngine.length > 0) {

    // }

    /*return this.firestore.collection('images',
      (ref) =>
        ref.where('projectId', '==', projectId)
          .where('deleted', '==', false))
      .get().pipe(
        handleFirebaseError(this.auth),
        map(images => {
          images.docs.forEach(docI => {
            const qs = this.firestore.collection('annotations', (ref) => ref.where('imageId', '==', docI.id)).get()
            return fromPromise(qs.forEach(doc => {
              doc.docs.length > 0 ? doc.docs[0].ref.delete() : null
            })
            )
          })
        })
      );
      */
  }

  runEngine(param): Observable<any> {
    console.log('--------------RUN Engine---------------')
    return this.httpClient.post(
      environment.engineURL,
      param,
      {
        headers: {
          'X-API-KEY': '668ec069a0473c0eaf3414fe9bc885de',

        }
      }
    );
  }

  dxfExtract(docId): Observable<any> {
    return this.httpClient.post(environment.dxfUrl + `extract_dxf_info/`, {
      docId: docId
    });
  }

  kmzExtract(docId): Observable<any> {
    return this.httpClient.post(environment.dxfUrl + `extract_shp_info/`, {
      docId: docId
    });
  }

  polygonImages(projectId, locations) {
    return this.httpClient.post(environment.imagePolygons, {

      "projectId": projectId,
      "polygon": locations

    });
  }

  getSkippedImages(projectId: string): Observable<any> {
    const annotationRef = this.firestore
      .collection(`annotations`, (ref) =>
        ref.where('projectId', '==', projectId));
    return annotationRef.get().pipe(
      handleFirebaseError(this.auth, 'getSkippedImages'),
      switchMap(annotations => {
        const verifiedEntries = annotations.docs.filter(o => o.data().verifiedAnnotations).map(o => o.data())
        const manualEntries = annotations.docs.filter(eachVal => {
          let manualEntry = eachVal.data().polygons?.some(o => !o.aiEngineGenerated)
          return manualEntry;
        }).map(o => o.data())
        return of(verifiedEntries.concat(manualEntries));
      })
    )

  }

  //Temp function to set projectId in annotations collections
  /**only for admin  */
  async addProjectIdToAnnotations(): Promise<any> {
    const db = this.firestore.firestore;
    async function getImages() {
      const images: any = await db.collection('images')
        .get();
      return images.docs;
    }
    async function getAnnotations() {
      const annotations: any = await db.collection('annotations')
        .get();

      return annotations.docs;
    }
    const docs: any = await getAnnotations();
    const imagesDocs: any = await getImages();
    let currentBatch = db.batch();
    let currentBatchSize = 0;
    // prepare the batch
    const batches = [currentBatch];
    // add each doc's deletion to the batch
    docs.filter(o => !o.data().projectId).forEach((doc) => {
      // when batch is too large, start a new one
      if (++currentBatchSize >= 500) {
        currentBatch = db.batch();
        batches.push(currentBatch);
        currentBatchSize = 1;
      }
      // add operation to batch
      const find = imagesDocs.find(o => o.id === doc.data().imageId)
      if (find && !doc.data().projectId) {
        currentBatch.update(doc.ref, { projectId: find.data().projectId });
      }
    })
    // commit the changes
    await Promise.all(batches.map(batch => batch.commit()));
  }

  getAssetById(assetId): Observable<any> {
    return this.firestore.collection('assets').doc(assetId).get()
  }

  imageSimilarityTrain(projectId): Observable<any> {
    return this.httpClient.post(
      environment.imageSimilarity,
      {
        "mode": "train",
        "projectId": projectId
      }
    );
  }

  linkDXFImages(projectId): Observable<any> {
    return this.httpClient.post(environment.dxfUrl + `link_images/`, {
      projectId: projectId
    });
  }

  linkDXFMedias(projectId, modelId, assetId, medias): Observable<any> {
    return this.httpClient.post(environment.dxfUrl + `link_images/`, {
      projectId: projectId
    });
  }
  imageSimilaritySearch(params): Observable<any> {
    return this.httpClient.post(
      environment.imageSimilarity, params
    );
  }

  getImageSimilarity(projectId): Observable<any> {
    return this.firestore
      .collection(`image-similarity`, (ref) =>
        ref.where('projectId', '==', projectId)
          .orderBy('createdTime', 'desc')
          .limit(1),
      ).valueChanges({ idField: '_id' }).pipe(
        debounceTime(500),
        handleFirebaseError(this.auth, 'getImageSimilarity')
      );
  }

  getProjectUnprocessedImages(projectId): Observable<any> {
    return this.firestore.collection('projects').doc(projectId).collection('unprocessed-images')
      .valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'getProjectUnprocessedImages')
      );
  }

  getTechnicalDrawingAnnotations$<T extends any>(imageId: string): Observable<T> {
    if (!imageId) { console.error('Error: imageId is absent!'); return of() };
    return this.firestore
      .collection<T>('technical-drawing', (ref) => ref.where('technicalDrawingId', '==', imageId))
      .valueChanges().pipe(
        debounceTime(500),
        handleFirebaseError(this.auth, 'getTechnicalDrawingAnnotations$'),
        map(r => {
          return r.length > 0 ? r[0] : null
        })
      );
  }

  closeManualGeoPosition(id: string): Observable<any> {
    const ref = this.firestore.collection('technical-drawing').doc(id);
    return fromPromise(ref.update({
      manualLinking: true
    }));
  }

  createTechnicalDrawingAnnotation(
    technicalDrawingId: string,
    projectId: string,
    assetId: string,
    polygon: { points: number[] },
    fileUrl: string,
    relationImageId: string,
    id?: string,

  ): Observable<any> {
    const collection = this.firestore.collection('technical-drawing', (ref) => ref.where('technicalDrawingId', '==', technicalDrawingId));
    const { displayName, uid } = this.getCurrentUser();
    const lastModifiedBy = { displayName, uid };

    const addPolygon = { relationImageId: relationImageId, projectId: projectId, id: id || uuidv4(), polygon, lastModifiedBy: lastModifiedBy };
    return collection.get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return fromPromise(this.firestore.collection('technical-drawing').add({
        technicalDrawingId,
        assetId,
        fileUrl,
        polygons: [addPolygon],
        tag: 'context'
      }));

      const annotations = r.docs[0];
      const polygons = Array.isArray(annotations.data().polygons) ? annotations.data().polygons : [];
      const newPolygons = polygons.filter(x => x.id !== id);
      return fromPromise(annotations.ref.update({
        ...annotations.data(),
        tag: 'context',
        polygons: [
          ...newPolygons,
          addPolygon
        ]
      }));
    }));
  }

  removeTechnicalDrawingAnnotation(id: string, technicalDrawingId: string): Observable<any> {
    const collection = this.firestore.collection('technical-drawing', (ref) => ref.where('technicalDrawingId', '==', technicalDrawingId));

    return collection.get().pipe(switchMap((r: any) => {
      if (!r.docs.length) return of('');

      const annotations = r.docs[0];

      const polygons = Array.isArray(annotations.data().polygons) ? annotations.data().polygons : [];

      const newPolygons = polygons.filter(x => x.id !== id);

      return fromPromise(annotations.ref.update({
        ...annotations.data(),
        polygons: [
          ...newPolygons,
        ]
      }));
    }));
  }

  trainedProject(projectId: string, trainedForNewImages: boolean) {
    return this.firestore.collection('projects').doc(projectId).update({ trainedForNewImages });
  }

  linkImages(assetId: string,
    modelId: string,
    keyId: string,
    linkImages = [],
    type: string,
    projectId: string,
    modelType: string
  ): Observable<any> {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    linkImages = linkImages.filter(onlyUnique)
    return this.firestore
      .collection('link-images', (ref) => ref
        .where('assetId', '==', assetId)
        .where('projectId', '==', projectId)
        .where('modelId', '==', modelId)).get().pipe(switchMap((r: any) => {
          if (!r.docs.length) {
            return fromPromise(
              this.firestore
                .collection('link-images')
                .add({
                  [keyId]:
                  {
                    [type]: linkImages,

                  },
                  modelId: modelId,
                  assetId: assetId,
                  projectId: projectId,
                  modelType: modelType
                }));
          }

          const images = r.docs[0].data()[keyId] && r.docs[0].data()[keyId][type] ? r.docs[0].data()[keyId][type].concat(linkImages).filter(onlyUnique) : linkImages;
          return fromPromise(this.firestore
            .collection('link-images').doc(r.docs[0].id).update({
              [`${keyId}.${type}`]: images
            }));
        }));
  }

  createLinkingIfNotExist(assetId: string,
    modelId: string,
    projectId: string
  ): Observable<any> {
    return this.firestore
      .collection('link-images', (ref) => ref
        .where('assetId', '==', assetId)
        .where('projectId', '==', projectId)
        .where('modelId', '==', modelId)).get().pipe(switchMap((r: any) => {
          if (!r.docs.length) {
            return fromPromise(
              this.firestore
                .collection('link-images')
                .add({
                  modelId: modelId,
                  assetId: assetId,
                  projectId: projectId
                }));
          }
          return of(true);
        }));
  }

  removedLinkedImages(assetId: string, modelId: string, keyId: string, linkImages, type: string, projectId: string): Observable<any> {
    return this.firestore
      .collection('link-images', (ref) => ref
        .where('assetId', '==', assetId)
        .where('modelId', '==', modelId)
        .where('projectId', '==', projectId)
      ).get().pipe(
        handleFirebaseError(this.auth, 'removedLinkedImages'),
        map(r => {
          if (r.docs.length) {
            return fromPromise(this.firestore
              .collection('link-images').doc(r.docs[0].id).update({
                [`${keyId}.${type}`]: linkImages
              }));
          }
        })
      );
  }


  dXFLinkedImages(projectId: string, jsonData, assetId, modelId, linkRef): Observable<any> {
    const ref = this.storage.ref('DXF_data/LinkedImage_json/').child(linkRef && linkRef.linkFilename ? linkRef.linkFilename : `${projectId}_labelImages.json`)
    return from(ref.putString(JSON.stringify(jsonData))).pipe(switchMap((response) => {
      return from(response['ref'].getDownloadURL()).pipe(switchMap(downloadUrl => {
        if (linkRef) {
          return fromPromise(this.firestore
            .collection('link-images').doc(linkRef.id).update({
              linkFile: downloadUrl
            }))
        } else {
          return fromPromise(
            this.firestore
              .collection('link-images')
              .add({
                linkFile: downloadUrl,
                modelId: modelId,
                assetId: assetId,
                projectId: projectId,
                modelType: '2D'
              }));

        }
      }));
    }));
  }

  reOrderedImages(assetId: string, modelId: string, keyId: string, linkImages, type: string, projectId: string): Observable<any> {
    return this.firestore
      .collection('link-images', (ref) => ref
        .where('assetId', '==', assetId)
        .where('modelId', '==', modelId)
        .where('projectId', '==', projectId)
      ).get().pipe(
        handleFirebaseError(this.auth, 'reOrderedImages'),
        map(r => {
          if (r.docs.length) {
            return fromPromise(this.firestore
              .collection('link-images').doc(r.docs[0].id).update({
                [`${keyId}.${type}`]: linkImages
              }));
          }
        })
      );
  }

  getLinkedImages(assetId: string, modelId: string, projectId: string) {
    if (!projectId || !modelId) { return of(null) }
    return this.firestore
      .collection('link-images', (ref) => ref
        .where('assetId', '==', assetId)
        .where('modelId', '==', modelId)
        .where('projectId', '==', projectId)

      ).get().pipe(
        handleFirebaseError(this.auth, 'getLinkedImages'),
        map(r => {
          return r.docs.length > 0 ? {
            ...r.docs[0].data(),
            id: r.docs[0].id
          } : null
        })
      );
  }

  getLinkedImages$(assetId: string, modelId: string, projectId: string) {
    if (!projectId) { return of() }
    return this.firestore
      .collection('link-images', (ref) => ref
        .where('assetId', '==', assetId)
        .where('modelId', '==', modelId)
        .where('projectId', '==', projectId)

      ).valueChanges({ idField: 'id' }).pipe(
        debounceTime(200),
        handleFirebaseError(this.auth, 'getLinkedImages$'),
        map(r => {
          return r.length ? r[0] : null;
        })
      );
  }

  getDXFLinkedImages(assetId: string, modelId: string, projectId: string) {
    if (!projectId) { return of() }
    return this.firestore
      .collection('link-images', (ref) => ref
        .where('assetId', '==', assetId)
        .where('modelId', '==', modelId)
        .where('projectId', '==', projectId)

      ).get().pipe(
        debounceTime(500),
        map(r => {
          return r.docs.length ? r.docs[0].data() : null;
        })
      );
  }

  get3DModels$<T extends any>(assetId: string): Observable<T> {
    if (!assetId) { console.error('Error: assetId is absent!'); return of() };
    return this.firestore
      .collection<T>('models', (ref) => ref.where('assetId', '==', assetId))
      .valueChanges({ idField: 'id' }).pipe(
        debounceTime(500),
        handleFirebaseError(this.auth, 'get3DModels$'),
        map(r => {
          return r.length > 0 ? r[0] : null
        })
      );
  }


  get3DModels<T extends any>(assetId: string): Observable<T> {
    if (!assetId) { console.error('Error: assetId is absent!'); return of() };
    return this.firestore
      .collection<T>('models', (ref) => ref.where('assetId', '==', assetId))
      .get().pipe(
        handleFirebaseError(this.auth, 'get3DModels'),
        map(r => {
          return r.docs.length > 0 ? {
            ...r.docs[0].data(),
            id: r.docs[0].id
          } : null
        })
      );
  }

  private getLabels(collection: string, response: any): { labelRef: any, drawings: any } {
    const labelRef = [];
    response.forEach((doc: any) => {
      labelRef.push(this.firestore
        .collection(collection).doc(doc.id).collection("annotations").doc("labels").get()
      )
    });
    return ({ labelRef, drawings: response });
  }

  get2DModels$<T extends any>(assetId: string): Observable<T> {
    if (!assetId) { console.error('Error: assetId is absent!'); return of() };
    return this.firestore
      .collection<T>('technical-drawing', (ref) => ref.where('assetId', '==', assetId)
        .orderBy('createdTime')
      )
      .valueChanges({ idField: 'id' }).pipe(
        debounceTime(500),
        map((res): any => this.getLabels('technical-drawing', res.length ? res : [])),
        switchMap(({ labelRef, drawings }) => {
          return combineLatest([
            ...labelRef.map((ref) => {
              return ref;
            }),
            of([])
          ]).pipe(
            map((children: any) => {
              const teachDrawings: any = [];
              drawings.forEach((image, i) => {
                teachDrawings.push({ ...image, labels: children[i].data()?.label || [] })
              });
              return teachDrawings;
            })
          );
        }),
      );
  }

  get2DDXFModels$<T extends any>(assetId: string): Observable<T> {
    if (!assetId) { console.error('Error: assetId is absent!'); return of() };
    return this.firestore
      .collection<T>('technical-drawing', (ref) => ref.where('assetId', '==', assetId)
        .where("isDXFFile", "==", true))
      .valueChanges({ idField: 'id' }).pipe(
        debounceTime(500),
        handleFirebaseError(this.auth, 'get2DDXFModels$'),
        map(r => {
          return r.length > 0 ? r[0] : null
        })
      );
  }

  link3DModel$(assetId: string, data: any): Observable<any> {
    if (!assetId) { console.error('Error: assetId is absent!'); return of() };
    return fromPromise(this.firestore
      .collection('models').add({
        assetId: assetId,
        createdBy: this.currentUser.uid,
        createdTime: new Date(),
        type: "3D",
        ...data
      }
      ));
  }

  updateLink3DModels$(modelId: string, data: any): Observable<any> {
    return fromPromise(this.firestore
      .collection('models').doc(modelId).update(data
      ));
  }


  add3DModels$(assetId: string, fileUrl): Observable<any> {
    if (!assetId) { console.error('Error: assetId is absent!'); return of() };
    return fromPromise(this.firestore
      .collection('models').add({
        assetId: assetId,
        model_zipfileUrl: fileUrl,
        createdBy: this.currentUser.uid,
        createdTime: new Date(),
        type: "3D",
        ownModel: true
      }
      ));
  }

  update3DModels$(modelId: string, fileUrl): Observable<any> {
    return fromPromise(this.firestore
      .collection('models').doc(modelId).update({
        model_zipfileUrl: fileUrl,
        createdBy: this.currentUser.uid,
        createdTime: new Date(),
        type: "3D",
        zipfileUrl: null,
        ownModel: true
      }
      ));
  }
  lockOrientation(modelId: string): Observable<any> {
    return fromPromise(this.firestore
      .collection('models').doc(modelId).update({
        orientationLocked: true
      }
      ));
  }

  add2DModels$(assetId: string, fileUrl, fileName: string, isDXFFile: boolean = false, isKMLFile: boolean = false, isShapeFile: boolean = false): Observable<any> {
    if (!assetId) { console.error('Error: assetId is absent!'); return of() };
    return fromPromise(this.firestore
      .collection('technical-drawing').add({
        assetId: assetId,
        fileName: fileName,
        fileUrl: fileUrl,
        ...(isShapeFile ? { shp_zipfileUrl: fileUrl } : { fileUrl: fileUrl }),
        createdBy: this.currentUser.uid,
        createdTime: new Date(),
        isDXFFile: isShapeFile ? true : isDXFFile,
        isKMLFile: isShapeFile ? true : isKMLFile,
        isShapeFile: isShapeFile
      }
      ));
  }

  update2DModels$(id: string, params: any): Observable<any> {
    if (!id) { console.error('Error: id is absent!'); return of() };
    return fromPromise(this.firestore
      .collection('technical-drawing').doc(id).update(params));
  }

  get3DModelLabels$<T extends any>(modelId: string): Observable<T> {
    return this.firestore
      .collection<T>('models').doc(modelId).collection('annotations').doc('labels')
      .valueChanges({ idField: 'id' }).pipe(
        debounceTime(500),
        handleFirebaseError(this.auth, 'get3DModelLabels$'),
        map(r => {
          return r ? r : []
        })
      );
  }

  create3DModelLabels(modelId: string, label): Observable<any> {
    return this.firestore.collection('models').doc(modelId).collection('annotations').doc('labels')
      .get().pipe(switchMap((r: any) => {
        if (!r.exists) {
          return fromPromise(
            this.firestore.collection('models').doc(modelId).collection('annotations').doc('labels').
              set({
                label: label
              }));
        }
        return fromPromise(
          this.firestore.collection('models').doc(modelId).collection('annotations').doc('labels').update({
            label: r.data().label.concat(label)
          }));
      }));

  }

  remove3DModelLabels(modelId: string, label): Observable<any> {
    return this.firestore.collection('models').doc(modelId).collection('annotations').doc('labels')
      .get().pipe(switchMap((r: any) => {
        const labels = r.data().label || [];
        const index = labels.findIndex(o => o.id == label.id);
        if (index != -1) {
          labels.splice(index, 1)
        }
        return fromPromise(
          this.firestore.collection('models').doc(modelId).collection('annotations').doc('labels').update({
            label: labels
          }));
      }));

  }

  removeLabelLinkImages(assetId: string, modelId: string, labelId: string) {
    return this.firestore
      .collection('link-images', (ref) => ref
        .where('assetId', '==', assetId)
        .where('modelId', '==', modelId)
      ).get().pipe(
        handleFirebaseError(this.auth, 'removeLabelLinkImages'),
        map(r => {
          if (r.docs.length) {
            return r.docs.forEach(doc => {
              fromPromise(this.firestore
                .collection('link-images').doc(doc.id).update(
                  {
                    [labelId]: firebase.firestore.FieldValue.delete()
                  }
                ));
            });

          }

        })
      );
  }

  removedDXFLabelLinkImages(assetId: string, modelId: string, labelsIds: any) {
    return this.firestore
      .collection('link-images', (ref) => ref
        .where('assetId', '==', assetId)
        .where('modelId', '==', modelId)
      ).get().pipe(
        handleFirebaseError(this.auth, 'removedDXFLabelLinkImages'),
        map(r => {
          if (r.docs.length) {
            const updates = labelsIds.reduce((acc, fieldName) => {
              acc[fieldName] = firebase.firestore.FieldValue.delete();
              return acc;
            }, {});
            return r.docs.forEach(doc => {
              fromPromise(this.firestore
                .collection('link-images').doc(doc.id).update(
                  updates
                ));
            });

          }

        })
      );
  }
  uploadBase3DFile(file: IFile) {
    const ref = this.storage.ref('baseFiles').child(uuidv4());
    if (file) {
      return from(ref.put(file)).pipe(switchMap((response) => {
        return from(response['ref'].getDownloadURL()).pipe(switchMap(downloadUrl => {
          return of({
            link: downloadUrl,
            bucketName: response['ref'].name,
            name: file.name
          });
        }));
      }));
    } else {
      throw Error('File is undefined');
    }
  }

  uploadCSVFile(file) {
    const ref = this.storage.ref('CSV').child(uuidv4() + '.csv');
    if (file) {
      return from(ref.put(file)).pipe(switchMap((response) => {
        return from(response['ref'].getDownloadURL()).pipe(switchMap(downloadUrl => {
          return of({
            link: downloadUrl,
            bucketName: response['ref'].name,
            name: file.name
          });
        }));
      }));
    } else {
      throw Error('File is undefined');
    }
  }

  getVideoImagesContent(
    projectId: string,
    videoId: string
  ) {
    return this.firestore
      .collection(`images`, (ref) => ref
        .where('projectId', '==', projectId)
        .where('videoId', '==', videoId)
        .where('deleted', '==', false)
      )
  }

  exportQR(assetId: string, type: string): Observable<any> {
    return this.httpClient.get(
      `${environment.QRexport}?asset_id=${assetId}&type=${type}`,
    );
  }

  kmlGenerator(id: string): Observable<any> {
    return this.httpClient.post(
      `${environment.dxfUrl}/individual_kml_generator/`, {
      'docId': id
    }
    );
  }

  getTechnicalDrawinglLabels$<T extends any>(id: string): Observable<T> {
    return this.firestore
      .collection<T>('technical-drawing').doc(id).collection('annotations').doc('labels')
      .valueChanges({ idField: 'id' }).pipe(
        debounceTime(500),
        handleFirebaseError(this.auth, 'getTechnicalDrawinglLabels$'),
        map(r => {
          return r ? r : []
        })
      );
  }

  getDXFlLabels$<T extends any>(id: string): Observable<T> {

    return this.firestore.collection('technical-drawing').doc(id).collection('annotations').doc('labels').get().pipe(
      map((image: any) => {
        return {
          ...image?.data(),
        };
      }),
      handleFirebaseError(this.auth, 'getImage$')
    );

  }

  createTechnicalDrawingLabels(techDrawing, label, isDXF: boolean = false): Observable<any> {
    const ref = this.firestore.collection('technical-drawing').doc(techDrawing.id)
      .collection('annotations').doc('labels');
    return ref.get().pipe(switchMap((r: any) => {
      if (!r.exists) {
        return fromPromise(
          ref.set({
            label: label
          }));
      }
      return fromPromise(
        ref.update({
          label: isDXF ? label : r.data().label.concat(label)
        }));
    }));

  }

  removeTechnicalDrawingLabels(id: string, label): Observable<any> {
    const ref = this.firestore.collection('technical-drawing').doc(id)
      .collection('annotations').doc('labels');
    return ref.get().pipe(switchMap((r: any) => {
      const labels = r.data().label || [];
      const index = labels.findIndex(o => o.id == label.id);
      if (index != -1) {
        labels.splice(index, 1)
      }
      return fromPromise(
        ref.update({
          label: labels
        }));
    }));

  }

  getTerms(): Observable<any> {
    return this.firestore
      .collection('configuration').doc('terms-conditions').get()
  }

  tokenUpdate(uid, token) {
    return this.firestore.collection(`users`).doc(uid).update({ 'idToken': token });
  }

  async removeBaselineProjectLinkImages(assetId) {
    const query = this.firestore
      .collection('link-images',
        (ref) => ref.where('assetId', '==', assetId))
      .get();
    query.subscribe((snap) => {
      snap.docs.forEach(async linkImage => {
        await this.firestore.collection('link-images').doc(linkImage.id).delete();
      });
    })
  }

  async removeTechnicalDrawing(id: string) {
    await this.firestore.collection('technical-drawing').doc(id).collection("annotations").doc("labels").delete();
    return this.firestore.collection('technical-drawing').doc(id).delete()

  }

  uploadBase3DScreenshots(base64String: string) {
    const ref = this.storage.ref('baseFiles').child('screens').child(uuidv4());
    if (base64String) {
      return from(ref.putString(base64String, 'data_url', { contentType: 'image/jpg' })).pipe(switchMap((response) => {
        return from(response['ref'].getDownloadURL()).pipe(switchMap(downloadUrl => {
          return of({
            link: downloadUrl,
            bucketName: response['ref'].name
          });
        }));
      }));
    } else {
      throw Error('File is undefined');
    }
  }

  uploadLabelsJson(id: string, jsonData) {
    const ref = this.storage.ref('labels').child(id + ".json");
    return from(ref.putString(JSON.stringify(jsonData))).pipe(switchMap((response) => {
      return from(response['ref'].getDownloadURL()).pipe(switchMap(downloadUrl => {
        return of({
          link: downloadUrl,
          bucketName: response['ref'].name
        });
      }));
    }));
  }


  add3DScreenshots$(modelId: string, fileUrl: string): Observable<any> {
    return fromPromise(this.firestore
      .collection('models').doc(modelId).update({
        screens: firebase.firestore.FieldValue.arrayUnion({ fileUrl: fileUrl, title: '' })
      }
      ));
  }

  add2DScreenshots$(modelId: string, fileUrl: string): Observable<any> {
    return fromPromise(this.firestore
      .collection('technical-drawing').doc(modelId).update({
        screens: firebase.firestore.FieldValue.arrayUnion({ fileUrl: fileUrl, title: '' })
      }
      ));
  }

  defaultCameraPosition$(modelId: string, modelOptions): Observable<any> {
    return fromPromise(this.firestore
      .collection('models').doc(modelId).update({
        modelOptions: modelOptions
      }
      ));
  }

  defaultProjectCameraPosition$(projectId: string, modelOptions): Observable<any> {
    return fromPromise(this.firestore
      .collection('projects').doc(projectId).update({
        modelOptions: modelOptions
      }
      ));
  }

  projectModelOrientation$(projectId: string, orientation): Observable<any> {
    return fromPromise(this.firestore
      .collection('projects').doc(projectId).update({
        ["modelOptions.orientation"]: orientation
      }
      ));
  }

  baseModelOrientation$(modelId: string, orientation): Observable<any> {
    return fromPromise(this.firestore
      .collection('models').doc(modelId).update({
        ["modelOptions.orientation"]: orientation
      }
      ));
  }

  projectModelRCFile$(projectId: string, url: string): Observable<any> {
    return fromPromise(this.firestore
      .collection('projects').doc(projectId).update({
        rcFile: url
      }
      ));
  }

  baseModelRCFile$(modelId: string, url: string): Observable<any> {
    return fromPromise(this.firestore
      .collection('models').doc(modelId).update({
        rcFile: url
      }
      ));
  }
  update3DScreenshots$(modelId: string, screens): Observable<any> {
    return fromPromise(this.firestore
      .collection('models').doc(modelId).update({
        screens: screens
      }
      ));
  }

  update2DScreenshots$(modelId: string, screens): Observable<any> {
    return fromPromise(this.firestore
      .collection('technical-drawing').doc(modelId).update({
        screens: screens
      }
      ));
  }

  update2Dlabels$(modelId: string, labelsFile, levels): Observable<any> {
    return fromPromise(this.firestore
      .collection('technical-drawing').doc(modelId).update({
        labelsFile: labelsFile,
        levels: levels
      }
      ));
  }

  getAssetLabels<T extends any>(assetId: string, collection: string): Observable<T> {
    if (!assetId) { console.error('Error: assetId is absent!'); return of() };
    return this.firestore
      .collection<T>(collection, (ref) => ref.where('assetId', '==', assetId)
        .orderBy('createdTime')
      )
      .get()
      .pipe(
        map((res): any => {
          return this.getLabels(collection, res.docs.length ? res.docs : [])
        }),
        switchMap(({ labelRef, drawings }) => {
          return combineLatest([
            ...labelRef.map((ref) => {
              return ref;
            }),
            of([])
          ]).pipe(
            map((children: any) => {
              const teachDrawings: any = [];
              drawings.forEach((drawing, i) => {
                console
                teachDrawings.push({ ...drawing, labels: children[i].data()?.label || [] })
              });
              return teachDrawings;
            })
          );
        }),
      );
  }

  getProjectLinkedImages(assetId: string, projectId: string) {
    return this.firestore
      .collection('link-images', (ref) => ref
        .where('assetId', '==', assetId)
        .where('projectId', '==', projectId)

      ).get().pipe(
        handleFirebaseError(this.auth, 'getProjectLinkedImages'),
        map(r => {
          return r.docs.length ? r.docs : []
        })
      );
  }

  getProjectSummary(projectId) {
    return this.firestore
      .collection('project-summary').doc(projectId)
      .valueChanges({ idField: 'id' })
  }

  getSummary(projectId) {
    return this.firestore
      .collection('project-summary').doc(projectId)
      .get().pipe(
        handleFirebaseError(this.auth, 'getProjectSummaryByAsetId'),
        map(r => {

          return r.exists ? r.data() : null
        })
      );
  }

  getProjectSummaryByAsetId(assetId): Observable<any> {
    return this.firestore
      .collection('project-summary', (ref) => ref
        .where('assetId', '==', assetId)
      ).get().pipe(
        handleFirebaseError(this.auth, 'getProjectSummaryByAsetId'),
        map(r => {
          return r.docs.length ? r.docs : []
        })
      );
  }



  generate3DTiling(modelId: string): Observable<any> {
    return this.httpClient.post(
      `${environment.objToTiling}`, {
      modelId: modelId,
      userId: this.currentUser.uid
    })
  }


  getNotifications(): Observable<any> {
    return this.firestore
      .collection(`3D_notification`, (ref) =>
        ref.where('deleted', '==', false)
          .where('userId', '==', this.currentUser.uid)
          .orderBy('createdTime', 'desc')).valueChanges({ idField: 'id' })
  }

  deleteNotification(id): Promise<any> {
    return this.firestore.collection(`3D_notification`).doc(id).update({ deleted: true, deletedDate: new Date() })
  }

  readNotification(id): Promise<any> {
    return this.firestore.collection(`3D_notification`).doc(id).update({ isRead: true })
  }

  generate3DModel(projectId: string, ownModel: boolean, isBaseLineModel = false, cameraLens = 'normal')

    : Observable<any> {
    console.log('request 3d', {
      userId: this.currentUser.uid,
      projectId: projectId,
      ownModel: ownModel,
      isBaseLineModel: isBaseLineModel,
      cameraLens: cameraLens
    })
    return this.httpClient.post(
      `${environment.threeDModel}`, {
      userId: this.currentUser.uid,
      projectId: projectId,
      ownModel: ownModel,
      isBaseLineModel: isBaseLineModel,
      cameraLens: cameraLens
    });
  }


  cleanupAnnotations(projectId, tag) {
    return this.httpClient.post(
      `${environment.baseURL}/cleanup_annotation/`, { 'projectId': projectId, "tagName": tag });

  }

  cancel3DModel(id: string, type: string): Observable<any> {
    return this.httpClient.post(
      `${environment.cancelThreeDModel}`, {
      userId: this.currentUser.uid,
      docId: id,
      type: type
    });
  }

  updateProjectModelZip(id: string, fileUrl: string): Promise<any> {
    return this.firestore.collection('projects')
      .doc<any>(id).update({
        'model_zipfileUrl': fileUrl,
        tileStatus: 'requestProcessing'
      });
  }
  addLogExcel(id: string, fileUrl: string): Promise<any> {
    return this.firestore.collection('projects')
      .doc<any>(id).update({
        'inspectionFile': fileUrl
      });
  }

  updateProjectTileStatus(id: string, status): Promise<any> {
    return this.firestore.collection('projects')
      .doc<any>(id).update({
        tileStatus: status
      });
  }

  updateProjectMasking(id: string, isMask: boolean): Promise<any> {
    return this.firestore.collection('projects')
      .doc<any>(id).update({
        isMask: isMask
      });
  }
  updateProjectAlignment(id: string, alignments): Promise<any> {
    return this.firestore.collection('projects')
      .doc<Project>(id).update({ 'modelOptions': alignments, isAligned: true });
  }

  getServerTime() {
    return firebase.firestore.Timestamp.now().toDate();
  }

  addBaselineModels$(assetId: string): Observable<any> {
    if (!assetId) { console.error('Error: assetId is absent!'); return of() };
    return fromPromise(this.firestore
      .collection('models').add({
        assetId: assetId,
        createdBy: this.currentUser.uid,
        createdTime: new Date(),
        tileStatus: 'baseline'
      }
      ));
  }

  markAsBaseline$(assetId: string, size: number, gltfUrl: [], modelOptions: any, ownModel: boolean = false, tilesUrl: string): Observable<any> {
    return fromPromise(this.firestore
      .collection('models').add({
        assetId: assetId,
        createdBy: this.currentUser.uid,
        createdTime: new Date(),
        tileStatus: 'success',
        tilesUrl: tilesUrl,
        gltfUrl: gltfUrl,
        modelOptions: modelOptions,
        size: size,
        ownModel: ownModel
      }
      ));
  }
  updateBaselineStatus$(modelId: string): Observable<any> {
    return fromPromise(
      this.firestore.collection('models').doc(modelId).update({
        ['tileStatus']: firebase.firestore.FieldValue.delete(),
        ['baselineRefId']: firebase.firestore.FieldValue.delete()
      })
    );
  }

  getUnlabelledImages(assetId: string, baseModel: string, projectId: string) {
    return this.httpClient.post(
      `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/getUnlabelledImages`,
      {
        projectId: projectId,
        assetId: assetId,
        baseModel: baseModel
      }
    );
  }

  /* croppedLinkedImage(projectId: string, annotationId: string, imageId: string, polygonId: string, folderPath: string) {
     return this.httpClient.post(
       `${environment.cropping}`,
       {
         projectId: projectId,
         annotationId: annotationId,
         imageId: imageId,
         polygonId: polygonId,
         folderPath: folderPath
       }
     );
   }*/

  alignedModel(data: any) {
    return this.httpClient.post(
      `${environment.alignment}`, data
    );
  }

  getFile(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: 'text' });
  }

  getExcelFile(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  getCropedImage$(fileUrl, projectId): Observable<any> {
    return this.firestore
      .collection('images', (ref) =>
        ref
          .where('fileUrl', '==', fileUrl)
          .where('projectId', '==', projectId)
      )
      .valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'getCropedImage$'),
        map(images => images.length ? images[0] : null)
      );
  }


  addUsersToCampaign(campaignId: string, assets = []) {
    return this.httpClient.post(
      `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/addUsersCampaigns`,
      {
        campaignId: campaignId,
        assets: assets
      }
    );
  }



  getImageData(imageIds: string[], projectId: string): Observable<any> {
    const url = `${environment.dxfUrl}/extract_bbox_panels/`;
    return this.httpClient.post(url, {
      projectId: projectId,
      images: imageIds
    });
  }

  getHandlesForBatch(imageIds: string[], projectId: string): Observable<any[]> {
    // Create an array of Observables for each HTTP request
    const batchSize = 10;
    const batches: string[][] = [];
    for (let i = 0; i < imageIds.length; i += batchSize) {
      batches.push(imageIds.slice(i, i + batchSize));
    }

    // Map each batch to an Observable using getImageData
    const batchRequests: Observable<any>[] = batches.map(batch => this.getImageData(batch, projectId));

    // Use forkJoin to wait for all batch requests to complete
    return forkJoin(batchRequests);

  }



  deleteAsset(assetId: string) {
    return this.httpClient.post(
      `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/deleteAsset`,
      {
        assetId: assetId,
        userId: this.currentUser.uid
      }
    );
  }


  createFeaturesandFolders(lastProjectId: string, projectId: string, isFolders: boolean, isFeatures: boolean) {
    return this.httpClient.post(
      `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/setProjectData`,
      {
        lastProjectId: lastProjectId,
        projectId: projectId,
        isFolders: isFolders,
        isFeatures: isFeatures,
        userId: this.currentUser.uid
      }
    );
  }

  getProjectDocuments(projectId): Observable<any> {
    return this.firestore
      .collection('projects').doc(projectId).collection('documents')
      .valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'getProjectDocuments')
      );
  }

  addProjectDocuments(projectId, documets): Promise<any> {
    return this.firestore
      .collection('projects').doc(projectId).collection('documents')
      .add(documets);
  }

  deleteProjectDocuments(projectId, id): Promise<any> {
    return this.firestore
      .collection('projects').doc(projectId).collection('documents')
      .doc(id).delete();
  }

  includeCertificate(projectId, params): Promise<any> {
    return this.firestore
      .collection('projects').doc(projectId)
      .update(params);
  }

  deleteModel(id: string) {
    return this.firestore
      .collection('models').doc(id).delete();
  }

  deleteProjectModel(id: string) {
    return this.firestore
      .collection('projects').doc(id).update(
        {
          'modelOptions': firebase.firestore.FieldValue.delete(),
          'model_zipfileUrl': firebase.firestore.FieldValue.delete(),
          'ownModel': firebase.firestore.FieldValue.delete(),
          'cameraLens': firebase.firestore.FieldValue.delete(),
          'alignStatus': firebase.firestore.FieldValue.delete(),
          'approxModelExecutionTime': firebase.firestore.FieldValue.delete(),
          'executionTime': firebase.firestore.FieldValue.delete(),
          'gltfUrl': firebase.firestore.FieldValue.delete(),
          'isAligned': firebase.firestore.FieldValue.delete(),
          'isBaseLineModel': firebase.firestore.FieldValue.delete(),
          'tilesUrl': firebase.firestore.FieldValue.delete(),
          'tileStatus': firebase.firestore.FieldValue.delete(),
          'size': firebase.firestore.FieldValue.delete()
        }
      );
  }

  deleteDXFModel(id: string) {
    return this.firestore
      .collection('technical-drawing').doc(id).delete();
  }

  private emailFooter() {
    return `<br> 
    <br>
    <br>
    Best Regards <br>
    Oceans.ai pte ltd DeepDIVE team.<br>
    <a href="mailto:sales@oceansai.tech" style="direction:ltr;font-size:14px;line-height:32px;color:#4456F9; text-decoration: none;">sales@oceansai.tech</a>
    <br>
    <br>
  <div style="border-collapse:collapse;background-color:#eeeeee;border-bottom:1px solid #c8c8c8;padding:40px;text-align: center;"> 
  <a href="http://oceansai.tech/">
  <img style="height: 30px; margin: 0 auto;    " src="https://firebasestorage.googleapis.com/v0/b/dive-testing-268508.appspot.com/o/logos%2Flogo-footer.png?alt=media&token=0ae5c386-f7c9-4747-9f0f-8b4638502104">
  </a>
    </div>`
  }

  getCampaignList(): Observable<any> {
    const { user } = this.authProcess;
    return this.firestore.collection('campaign', (ref) =>
      ref.where(`users`, 'array-contains', user.uid)
    ).valueChanges({ idField: 'id' }).pipe(
      handleFirebaseError(this.auth, 'getCampaigns')
    );
  }


  projectColorPalette(id: string, colorPalette: string): Promise<any> {
    return this.firestore.collection('projects')
      .doc<any>(id).update({
        colorPalette: colorPalette
      });
  }

  getLogData(logFiles) {
    return this.httpClient.post(
      `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/getLogData`,
      {
        "logFileUrl": logFiles,
      }
    );
  }


  addImagesToVideos(images): Observable<any> {
    const batch = this.firestore.firestore.batch();
    images.forEach(update => {
      const updateData = {
        frame: update.frame,
        videoId: update.videoId,
        logFrame: update.logFrame
      }
      const docRef = this.firestore.collection('images').doc(update.imageId).ref;
      batch.update(docRef, updateData);
    });
    return from(batch.commit());
  }

  // Function to update documents in batches
  updateDocumentsInBatch(collectionPath: string, updates: any[]): Promise<void> {
    const batch = this.firestore.firestore.batch();

    updates.forEach(update => {
      const docRef = this.firestore.collection(collectionPath).doc(update.id).ref;
      batch.update(docRef, update.data);
    });

    // Commit the batched write
    return batch.commit();
  }


  uploadLogs(projectId: string, url: string)
    : Observable<any> {
    return this.httpClient.post(
      `${environment.logProcessUrl}`, {
      zipFilePath: url,
      projectId: projectId
    });
  }

  getDocumentZip(reportId: string, lists: string[]) {
    return this.httpClient.post(
      `${environment.baseAPIUrl}/create-zip`,
      {
        "report_id": reportId,
        "files_to_zip": lists
      }
    );
  }

  addDoaminReportBlock(domain: string, content): Promise<any> {
    return this.firestore.collection('report-block')
      .doc("template").collection(domain).add(content);
  }

  editDoaminReportBlock(domain: string, content, id: string): Promise<any> {
    return this.firestore.collection('report-block')
      .doc("template").collection(domain).doc(id).update(content);
  }

  getReportsBlocks(domain): Observable<any> {
    // const { user } = this.authProcess;
    return this.firestore.collection('report-block')
      .doc("template").collection(domain).valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'getReports')
      );
  }
  deleteReportBlock(id: string, domain): Promise<any> {
    return this.firestore.collection('report-block').doc("template").collection(domain).doc(id).delete();
  }

  /* addZoomedPositions(imageId: string, projectId:string, positions) {
     const annotationCollection = this.firestore.collection('annotations', (ref) => ref.where('imageId', '==', imageId));
     return annotationCollection.get().pipe(
       switchMap((response:any) => {
         if (response.docs[0]) {
           const zoomedPositions  =response.docs[0].data().zoomedPositions || [];
           return response.docs[0].ref.update({
             zoomedPositions: zoomedPositions.concat([{ ...positions, id: uuidv4() }])
           });
         } else {
           return annotationCollection.add({
             imageId,
             zoomedPositions: [{ ...positions, id: uuidv4() }],
             projectId: projectId,
            
           })
         }
       })
     )
   }*/

  addZoomedPositions(imageId: string, projectId: string, positions) {
    const params = `?scale=${positions.scale}&imageId=${imageId}&positionX=${positions.positionX}&positionY=${positions.positionY}&canvasWidth=${positions.canvasWidth}&canvasHeight=${positions.canvasHeight}`
    return this.httpClient.get(`${environment.zoomedImage}${params}`);
  }



  getZoomedImages$<T extends any>(imageId: string): Observable<T> {
    if (!imageId) { console.error('Error: imageId is absent!'); return of() };
    return this.firestore
      .collection<T>('associated-images', (ref) => ref.where('imageId', '==', imageId))
      .valueChanges({ idField: 'id' }).pipe(
        handleFirebaseError(this.auth, 'getZoomedImages$'),
        map(r => {
          return r.length > 0 ? r[0] : null
        })
      );
  }

  updateZoomedPositions(imageId: string, projectId: string, childImageId) {
    const collection = this.firestore.collection('associated-images', (ref) => ref.where('imageId', '==', imageId));
    return collection.get().pipe(
      switchMap((response: any) => {
        if (response.docs[0]) {
          const obj = response.docs[0].data()?.zoomedImages;
          delete obj[childImageId];
          if (Object.keys(obj).length === 0) {
            this.firestore.collection('associated-images').doc( response.docs[0].id).delete();
          } else {
            return response.docs[0].ref.update({
              zoomedImages: obj
            });
          }
        }
      })
    )

  }

}
